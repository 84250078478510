var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shipments-detail" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Shipments Detail",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "/new"
        }
      }),
      !_vm.isBusy
        ? _c("ShipmentsSingleItem", {
            attrs: {
              order: _vm.order,
              planName: _vm.planName,
              shipment: _vm.shipment,
              items: _vm.items,
              tracking: _vm.tracking,
              shipEvents: _vm.shipEvents,
              events: _vm.events,
              channels: _vm.channels,
              statuses: _vm.statuses
            }
          })
        : _c(
            "div",
            { staticClass: "container-fluid pt-100 pt-md-80 pb-80 pb-md-0" },
            [
              _c(
                "div",
                { staticClass: "text-center text-gray-600 mt-20 my-2" },
                [
                  _c("b-spinner", { staticClass: "align-middle" }),
                  _c("strong", [_vm._v("Loading...")])
                ],
                1
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }