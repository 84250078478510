var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "fullOrderEditModal",
          attrs: {
            id: "modal-tall",
            size: "lg",
            title: "Rebook Shipment",
            "modal-class": "book-modal",
            "hide-footer": true
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("span", { staticClass: "d-block fs18 lh28 fw500 mt-8" }, [
                    _vm._v(" Edit Shipment - " + _vm._s(_vm.form.orderId) + " ")
                  ]),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "rounded" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-close-variant fs14" })]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.isOpen,
            callback: function($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen"
          }
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveButton("bookSave")
                }
              }
            },
            [
              _c("div", {}, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "span",
                      { staticClass: "fs18 lh28 fw500 d-block pb-38 pb-md-24" },
                      [_vm._v(" Order Information ")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "warehouse-field",
                            label: "Warehouse",
                            "label-for": "warehouse"
                          }
                        },
                        [
                          _c("multiselect", {
                            ref: "warehouse",
                            attrs: {
                              id: "warehouse",
                              options: _vm.editOrderWarehouses,
                              label: "name",
                              "show-labels": false,
                              "track-by": "name"
                            },
                            on: {
                              input: function($event) {
                                return _vm.Onchange(_vm.form.sellerAddressId)
                              }
                            },
                            model: {
                              value: _vm.form.sellerAddressId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sellerAddressId", $$v)
                              },
                              expression: "form.sellerAddressId"
                            }
                          }),
                          !_vm.form.sellerAddressId
                            ? _c("span", { staticClass: "fs12 text-red" }, [
                                _vm._v("Warehouse selection is required")
                              ])
                            : _c(
                                "span",
                                { staticClass: "fs12 text-gray-600" },
                                [_vm._v(_vm._s(_vm.warehouseAddress))]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "orderid-field",
                            label: "Order ID",
                            "label-for": "order-id"
                          }
                        },
                        [
                          _c("b-form-input", {
                            ref: "orderId",
                            staticClass: "order_id",
                            class: { sameOrderid: _vm.giveNeworder },
                            attrs: {
                              disabled: "",
                              id: "order-id",
                              minlength: "2",
                              maxlength: "35",
                              required: "",
                              pattern: "[0-9a-z#A-Z_ /-]*",
                              title: "Maximum 35 characters allowed",
                              placeholder: "Enter order id"
                            },
                            model: {
                              value: _vm.form.orderId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "orderId", $$v)
                              },
                              expression: "form.orderId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "date-field",
                            label: "Date",
                            "label-for": "date"
                          }
                        },
                        [
                          _c(
                            "date-picker",
                            {
                              ref: "orderDate",
                              attrs: {
                                disabled: "",
                                "prefix-class": "xmx",
                                id: "date",
                                placeholder: "yyyy-mm-dd"
                              },
                              model: {
                                value: _vm.form.orderDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "orderDate", $$v)
                                },
                                expression: "form.orderDate"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-calendar",
                                attrs: { slot: "icon-calendar" },
                                slot: "icon-calendar"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "type-field",
                            label: "Order Type",
                            "label-for": "type"
                          }
                        },
                        [
                          _c("multiselect", {
                            ref: "orderType",
                            attrs: {
                              id: "type",
                              options: _vm.types,
                              searchable: false,
                              "show-labels": false
                            },
                            model: {
                              value: _vm.form.orderType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "orderType", $$v)
                              },
                              expression: "form.orderType"
                            }
                          }),
                          !_vm.form.orderType
                            ? _c("span", { staticClass: "fs12 text-red" }, [
                                _vm._v("Order Type is required")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "total-value-field",
                            label: "Total Value",
                            "label-for": "total-value"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { append: "₹" } },
                            [
                              _c("b-input", {
                                ref: "totalValue",
                                attrs: {
                                  id: "total-value",
                                  placeholder: "0.00",
                                  required: "",
                                  min: "0.1",
                                  step: "any",
                                  type: "number"
                                },
                                model: {
                                  value: _vm.form.totalValue,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "totalValue", $$v)
                                  },
                                  expression: "form.totalValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm.user.choice == 0
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "mode-field",
                                label: "Mode",
                                "label-for": "weight"
                              }
                            },
                            [
                              _c("multiselect", {
                                ref: "modeType",
                                attrs: {
                                  id: "mode",
                                  options: _vm.modes,
                                  placeholder: "Select mode type",
                                  searchable: false,
                                  "show-labels": false,
                                  "track-by": "name",
                                  label: "name"
                                },
                                model: {
                                  value: _vm.form.modeType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "modeType", $$v)
                                  },
                                  expression: "form.modeType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.form.totalValue >= 50000
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: "E-WayBill",
                                "label-for": "ewaybill"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  id: "ewaybill",
                                  type: "text",
                                  placeholder: "E-WayBill",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.ewaybill,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "ewaybill", $$v)
                                  },
                                  expression: "form.ewaybill"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24"
                      },
                      [_vm._v(" Customer Information ")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "customer-name-field",
                            label: "Name",
                            "label-for": "customer-name"
                          }
                        },
                        [
                          _c("b-form-input", {
                            ref: "customerName",
                            attrs: {
                              id: "customer-name",
                              placeholder: "Enter customer name",
                              required: "",
                              maxlength: "35",
                              minlength: "3"
                            },
                            model: {
                              value: _vm.form.customerName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customerName", $$v)
                              },
                              expression: "form.customerName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "phone-field",
                            label: "Phone Number",
                            "label-for": "phone-number"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                ref: "contact",
                                attrs: {
                                  id: "phone-number",
                                  placeholder: "123 456 7890",
                                  type: "tel",
                                  required: "",
                                  maxlength: "10",
                                  minlength: "10",
                                  formatter: _vm.numbers,
                                  oninvalid:
                                    "this.setCustomValidity('Enter valid mobile number.')",
                                  oninput: "this.setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.contact,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "contact", $$v)
                                  },
                                  expression: "form.contact"
                                }
                              }),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c("div", { staticClass: "input-group-text" }, [
                                  _c("i", {
                                    staticClass: "icon-phone fs16 text-gray-600"
                                  })
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "city-field",
                            label: "City",
                            "label-for": "city"
                          }
                        },
                        [
                          _c("b-form-input", {
                            ref: "customerCity",
                            attrs: {
                              id: "city",
                              placeholder: "Enter city",
                              required: "",
                              maxlength: "35"
                            },
                            model: {
                              value: _vm.form.customerCity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customerCity", $$v)
                              },
                              expression: "form.customerCity"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                this.maxLength
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-9" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "address-field",
                                label: "Full Address",
                                "label-for": "address",
                                description:
                                  "Characters: " +
                                  _vm.form.customerAddress.length +
                                  " / " +
                                  this.maxLength
                              }
                            },
                            [
                              _c("b-form-input", {
                                ref: "customerAddress",
                                attrs: {
                                  id: "address",
                                  required: "",
                                  placeholder: "Enter customer address",
                                  maxlength: this.maxLength
                                },
                                model: {
                                  value: _vm.form.customerAddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerAddress", $$v)
                                  },
                                  expression: "form.customerAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 col-md-3" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "pin-field",
                                label: "Pin Code",
                                "label-for": "pin"
                              }
                            },
                            [
                              _c("b-form-input", {
                                ref: "customerPinCode",
                                attrs: {
                                  id: "pin",
                                  type: "text",
                                  maxlength: "6",
                                  minlength: "6",
                                  required: "",
                                  oninvalid:
                                    "this.setCustomValidity('Enter valid pincode.')",
                                  oninput: "this.setCustomValidity('')",
                                  formatter: _vm.numbers,
                                  placeholder: "110001"
                                },
                                model: {
                                  value: _vm.form.customerPinCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerPinCode", $$v)
                                  },
                                  expression: "form.customerPinCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24"
                    },
                    [_vm._v(" Package Details ")]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("b-table", {
                      staticClass: "dark-header new-order",
                      attrs: {
                        fields: _vm.computedFields,
                        items: _vm.form.items,
                        responsive: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "head(dimensions)",
                          fn: function() {
                            return [
                              _c("span", { staticClass: "underline-dashed" }, [
                                _vm._v(" Dimensions ")
                              ]),
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.right",
                                    value: "SKU Dimensions!",
                                    expression: "'SKU Dimensions!'",
                                    modifiers: { hover: true, right: true }
                                  }
                                ],
                                staticClass:
                                  "icon-tutorial ml-4 vam text-gray-400"
                              })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "head(sku)",
                          fn: function() {
                            return [_vm._v(" SKU ")]
                          },
                          proxy: true
                        },
                        {
                          key: "head(quantity)",
                          fn: function() {
                            return [_vm._v(" QTY. * ")]
                          },
                          proxy: true
                        },
                        {
                          key: "cell(sku)",
                          fn: function(data) {
                            return [_vm._v(" " + _vm._s(data.item.sku) + " ")]
                          }
                        },
                        {
                          key: "cell(name)",
                          fn: function(data) {
                            return [
                              _c("b-form-input", {
                                staticClass: "input-sm sku-name w40",
                                attrs: {
                                  placeholder: "Product Name",
                                  maxlength: "100"
                                },
                                model: {
                                  value: data.item.name,
                                  callback: function($$v) {
                                    _vm.$set(data.item, "name", $$v)
                                  },
                                  expression: "data.item.name"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "cell(dimensions)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w40",
                                    attrs: {
                                      placeholder: "0",
                                      type: "number",
                                      min: "0",
                                      max: "999"
                                    },
                                    model: {
                                      value: data.item.dimensions.length,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item.dimensions,
                                          "length",
                                          $$v
                                        )
                                      },
                                      expression: "data.item.dimensions.length"
                                    }
                                  }),
                                  _c("span", { staticClass: "px-4" }, [
                                    _vm._v("x")
                                  ]),
                                  _c("b-form-input", {
                                    staticClass: "small w40",
                                    attrs: {
                                      placeholder: "0",
                                      type: "number",
                                      min: "0",
                                      max: "999"
                                    },
                                    model: {
                                      value: data.item.dimensions.width,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item.dimensions,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression: "data.item.dimensions.width"
                                    }
                                  }),
                                  _c("span", { staticClass: "px-4" }, [
                                    _vm._v("x")
                                  ]),
                                  _c("b-form-input", {
                                    staticClass: "small w40",
                                    attrs: {
                                      placeholder: "0",
                                      type: "number",
                                      min: "0",
                                      max: "999"
                                    },
                                    model: {
                                      value: data.item.dimensions.height,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item.dimensions,
                                          "height",
                                          $$v
                                        )
                                      },
                                      expression: "data.item.dimensions.height"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(weight)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w72",
                                    attrs: {
                                      placeholder: "0.00",
                                      step: ".01",
                                      type: "number",
                                      max: "999.99",
                                      min: "0"
                                    },
                                    model: {
                                      value: data.item.weight,
                                      callback: function($$v) {
                                        _vm.$set(data.item, "weight", $$v)
                                      },
                                      expression: "data.item.weight"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(quantity)",
                          fn: function(data) {
                            return [
                              _c("b-form-input", {
                                staticClass: "small w40",
                                attrs: {
                                  placeholder: "0",
                                  type: "number",
                                  min: "0"
                                },
                                model: {
                                  value: data.item.quantity,
                                  callback: function($$v) {
                                    _vm.$set(data.item, "quantity", $$v)
                                  },
                                  expression: "data.item.quantity"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "cell(value)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w72",
                                    attrs: {
                                      step: ".01",
                                      placeholder: "0.00",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: data.item.value,
                                      callback: function($$v) {
                                        _vm.$set(data.item, "value", $$v)
                                      },
                                      expression: "data.item.value"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]),
              _vm.fields[0].key == "sku" || _vm.fields[1].key == "sku"
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24"
                        },
                        [_vm._v(" Net Dimensions ")]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.fields[0].key == "sku" || _vm.fields[1].key == "sku"
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("b-table", {
                          staticClass: "dark-header new-order",
                          attrs: {
                            fields: _vm.netfields,
                            items: _vm.form.packetdiamention,
                            responsive: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "head(dimensions)",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "underline-dashed" },
                                      [_vm._v(" Dimensions ")]
                                    ),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.right",
                                          value: "Net Dimensions LxWxH in cm",
                                          expression:
                                            "'Net Dimensions LxWxH in cm'",
                                          modifiers: {
                                            hover: true,
                                            right: true
                                          }
                                        }
                                      ],
                                      staticClass:
                                        "icon-tutorial ml-4 vam text-gray-400"
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "cell(dimensions)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-center"
                                      },
                                      [
                                        _c("span", { staticClass: "mr-5" }, [
                                          _vm._v("L")
                                        ]),
                                        _c("b-form-input", {
                                          staticClass: "small w50px",
                                          attrs: {
                                            required: "",
                                            placeholder: "0",
                                            type: "number",
                                            max: "999",
                                            min: "1"
                                          },
                                          model: {
                                            value: data.item.itemLength,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemLength",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemLength"
                                          }
                                        }),
                                        _c("span", { staticClass: "px-4" }, [
                                          _vm._v("x")
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "mr-5 ml-5" },
                                          [_vm._v("W")]
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "small w50px",
                                          attrs: {
                                            required: "",
                                            placeholder: "0",
                                            max: "999",
                                            type: "number",
                                            min: "1"
                                          },
                                          model: {
                                            value: data.item.itemWidth,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemWidth",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemWidth"
                                          }
                                        }),
                                        _c("span", { staticClass: "px-4" }, [
                                          _vm._v("x")
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "mr-5 ml-5" },
                                          [_vm._v("H")]
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "small w50px",
                                          attrs: {
                                            required: "",
                                            placeholder: "0",
                                            max: "999",
                                            type: "number",
                                            min: "1"
                                          },
                                          model: {
                                            value: data.item.itemHeight,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemHeight",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemHeight"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(weight)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-center"
                                      },
                                      [
                                        _c("b-form-input", {
                                          staticClass: "small w72 net-weight",
                                          attrs: {
                                            required: "",
                                            step: ".01",
                                            type: "number",
                                            min: "0.01",
                                            placeholder: "0.00",
                                            max: "999.99"
                                          },
                                          model: {
                                            value: data.item.itemWeight,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemWeight",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemWeight"
                                          }
                                        }),
                                        _c("span", { staticClass: "ml-4" }, [
                                          _vm._v(" kg ")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2630671799
                          ),
                          model: {
                            value: _vm.form.packetdiamention,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "packetdiamention", $$v)
                            },
                            expression: "form.packetdiamention"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "m-0 mb-20",
                  attrs: {
                    id: "btnHide",
                    variant: "primary",
                    type: "submit",
                    disabled: _vm.disableSave
                  }
                },
                [_vm._v("SAVE & BOOK")]
              ),
              _c("EditCalcPricing", {
                ref: "editcalcpricing",
                attrs: {
                  editShipment: _vm.editShipment,
                  formInfo: _vm.calcForm,
                  choiceData: _vm.calcData
                },
                on: {
                  openModal: _vm.openPricingModal,
                  "update:editShipment": function($event) {
                    _vm.editShipment = $event
                  },
                  "update:edit-shipment": function($event) {
                    _vm.editShipment = $event
                  },
                  "update:formInfo": function($event) {
                    _vm.calcForm = $event
                  },
                  "update:form-info": function($event) {
                    _vm.calcForm = $event
                  },
                  "update:choiceData": function($event) {
                    _vm.calcData = $event
                  },
                  "update:choice-data": function($event) {
                    _vm.calcData = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }