<template>
	<div class="shipments-detail">
		<AppTopbar
		title="Shipments Detail"
		buttonText="New Order"
		buttonIconClass="icon-add"
		buttonLink="/new"
		/>

		<ShipmentsSingleItem :order="order" :planName="planName" :shipment="shipment" :items="items" :tracking="tracking" :shipEvents="shipEvents" :events="events" :channels="channels" :statuses="statuses" v-if="!isBusy" />
		<div v-else class="container-fluid pt-100 pt-md-80 pb-80 pb-md-0">
			<div class="text-center text-gray-600 mt-20 my-2">
				<b-spinner class="align-middle"></b-spinner>
				<strong>Loading...</strong>
			</div>
		</div>
	</div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import ShipmentsSingleItem from "@/components/ShipmentsSingleItem.vue";

export default {
	name: "ShipmentsDetail",
	title: "Shyplite - Detail View",
	components: {
		AppTopbar,
		ShipmentsSingleItem,
	},
	data() {
		return {
			isBusy 		: false,
			planName	: null,
			order 		: {},
			shipment	: {},
			tracking	: {},
			channels	: {},
			items 		: {},
			shipEvents	: {},
			events 		: {},
			statuses 	: {},
		}
	},
	created() {
		this.getDetails()
		this.getChannels()
		this.getEvents()
		this.getStatus()
	},
	methods: {
		async getDetails() {
			try {
				this.isBusy = true
				const id = this.$route.params.id
				const res = await this.axios.get(`/shipment/${id}`)

				if (res.data.success) {
					this.order 		= res.data.shipment.order
					this.shipment 	= res.data.shipment.shipment
					this.tracking 	= res.data.shipment.tracking
					this.shipEvents = res.data.shipment.events
					this.isBusy 	= false
					this.planName	= res.data.shipment.plan
					this.items 		= res.data.shipment.order.packageDetails

				let pkgDim = undefined;

				if (this.shipment.packetdiamention){
					try {
						pkgDim = JSON.parse(this.shipment.packetdiamention);
					} catch(e) {
						pkgDim = this.shipment.packetdiamention;
					}

					if (Object.keys(pkgDim).length) {
						let a = {
							"sku":"", 
							// "category": "Net Dimension", 
							"name": "Net Dimension", 
							"length": pkgDim.itemLength, 
							"width": pkgDim.itemWidth, 
							"height": pkgDim.itemHeight, 
							"weight": pkgDim.itemWeight, 
							"modeType": "", 
							"quantity": "",
							"price": 0
						}

						this.items.push(a);

					}
				} 
					// this.popToast("booked", "OTP Sent!", res.data.message);
				} else {
					this.popToast("failed", "Failed!", res.data.message);
					setTimeout(() => this.$router.push('/shipments'), 2500);
					this.isBusy = false
				}
			} catch(e) {
				console.log(e);
			}
		},
		async getChannels() {
			const res = await this.axios.get('/statics/channels')
			this.channels = res.data
		},
		async getEvents() {
			const res = await this.axios.get('/statics/events')
			this.events = res.data
		},
		async getStatus() {
			const res = await this.axios.get('/statics/statuses')
			this.statuses = res.data.forwardStatus
		},
		goBack() {
			this.$router.go(-1);
		},
	},
	watch: {
		'$route.params.id': function(newValue) {
			this.getDetails()
		}
	}
};
</script>
