<template>
	<div class="container-fluid pt-64 pt-md-80 pb-80">
		<div class="row">
			<div class="col-8 col-md-6 pt-16 d-flex align-items-center">
				<b-button variant="icon-sm" @click="goBack">
					<i class="icon-previous fs9"></i>
				</b-button>
				<h2 class="lh32 ml-8">
					OrderID {{ order.importedID }}
				</h2>
			</div>
			
			<div class="col-4 col-md-6 pt-16 text-right">

				<b-button v-if="downloadSlipStatus == 'enabled'" @click="shipmentDownloadButton(shipment.id)" variant="primary" class="d-none d-md-inline-block mr-8">
					<i class="icon-download mr-10 fs16"></i>
					Download
				</b-button>


				<b-button v-if="shipment.finalStatus == 'Error' && (tracking.awbNo == null || tracking.awbNo == '' || tracking.awbNo == 'null') && order.channel != 9 && order.channel != 12 && order.channel != 37 && order.channel != 1 && showeditShipment " @click="editShipmentOpen(shipment.id)" variant="primary" class="d-none d-md-inline-block mr-8">
					Edit Shipment
				</b-button>

				<b-button v-if="order.channel != 9 && order.channel != 12 && order.channel != 37 " @click="rebookShipments(shipment.id)" variant="primary" class="d-none d-md-inline-block mr-8" :disabled="isBooking">
					<i class="icon-rebook mr-10 fs16"></i>
					<span class="fs14 lh24">
						Rebook <b-spinner v-if="isBooking" class="ml-10"></b-spinner>
					</span>
				</b-button>

				<b-button :disabled="isReversing" v-if="order.channel != 9 && order.channel != 12 && order.type.toLowerCase() != 'reverse' && order.channel != 37 " @click="bookReverseShipment(shipment.id)" variant="primary" class="d-none d-md-inline-block mr-8">
					<i class="icon-book-reverse mr-10 fs16"></i>
					<span class="fs14 lh24">
						Book - Reverse <b-spinner v-if="isReversing" class="ml-10"></b-spinner>
					</span>
				</b-button>

				<b-button v-if="tracking.status == 0 && tracking.isCancelled == false" @click="shipmentsCancelButton" variant="primary" class="d-none d-md-inline-block mr-8">
					<i class="icon-cancel mr-10 fs16"></i>
					<span class="fs14 lh24">
						Cancel
					</span>
				</b-button>


				<!-- Rebook, bookreverse, download, cancel button for mobile view -->
				<div class="order-menu order-menu__mobile d-sm-inline-block d-md-none bottom" v-click-outside="clickOutsideMobileMenu">
					<b-button @click="mobileMenuToggle = !mobileMenuToggle" variant="primary">
						<i class="icon-more fs16"></i>
					</b-button>
					<ul class="order-menu__list" :class="{ 'order-menu__list--collapsed': mobileMenuToggle }">

						<li :disabled="isBooking" v-if="order.channel != 9 && order.channel != 12 && order.channel != 37 ">
							<b-link @click="rebookShipments(shipment.id)">
								<i class="icon-rebook fs20 pr-12 text-blue"></i>
								<span class="fs14 lh24">
									Rebook <b-spinner v-if="isBooking" class="ml-10"></b-spinner>
								</span>
							</b-link>
						</li>

						<li :disabled="isReversing" v-if="order.channel != 9 && order.channel != 12 && order.type.toLowerCase() != 'reverse' && order.channel != 37 ">
							<b-link @click="bookReverseShipment(shipment.id)">
								<i class="icon-book-reverse fs20 pr-12 text-blue"></i>
								<span class="fs14 lh24">
									Book - Reverse <b-spinner v-if="isReversing" class="ml-10"></b-spinner>
								</span>
							</b-link>
						</li>

						<li v-if="tracking.status == 0 && tracking.isCancelled == false">
							<b-link @click="shipmentsCancelButton(shipment.id)">
								<i class="icon-cancel fs20 pr-12 text-blue"></i>
								<span class="fs14 lh24">
									Cancel
								</span>
							</b-link>
						</li>

						<li v-if="downloadSlipStatus == 'enabled'">
							<b-link @click="shipmentDownloadButton(shipment.id)">
								<i class="icon-download fs20 pr-12 text-blue"></i>
								<span class="fs14 lh24">
									Download Order
								</span>
							</b-link>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="row pt-16">
			<div class="col">
				<div class="shipment-grid">
					<div class="shipment">
						<span class="fs16 lh24 fw500">Shipment Details</span>
						<div class="row pt-16">
							<div class="col-8 col-md-3">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Booking Date
								</span>
								<span class="fs16 lh24 text-gray-900">
									{{ tracking.created | date }}, {{ tracking.created | time }}
								</span>
							</div>
							<div class="col-4 col-md-3">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Shipment ID
								</span>
								<span class="fs16 lh24 text-gray-900">
									{{ shipment.id }}
								</span>
							</div>
							<div class="col-8 col-md-3 mb-space">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Manifest ID
								</span>
								<span v-if="shipment.manifestID" class="fs16 lh24 cp text-blue" @click="$router.push('/shipments?manifestID='+shipment.manifestID)">
									{{ shipment.manifestID ? shipment.manifestID : 'N/A' }}
								</span>
								<span v-else class="fs16 lh24">
									{{ shipment.manifestID ? shipment.manifestID : 'N/A' }}
								</span>
							</div>

							<div class="col-4 col-md-3 mb-space">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Remittance Date
								</span>
								<span class="fs16 lh24 text-gray-900">
									{{ shipment.remittanceDate? 'Yes': 'N/A'}}
								</span>
							</div>
							<div class="col-8 col-md-3 pt-20">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Channel
								</span>
								<span v-if="order.channel > -1">
									<span v-if="channels[order.channel].name == 'MPS' || channels[order.channel].name == 'API'">
										{{ channels[order.channel].name | uppercase }}
									</span>
									<span v-else class="fs16 lh24 text-gray-900">
										{{ channels[order.channel].name | lowercase | capitalize }}
									</span>
								</span>
								<span v-else>
									N/A
								</span>
							</div>
							<div class="col-4 col-md-3 pt-20">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Estimated Amount
								</span>
								<span class="fs16 lh24 text-gray-900">
									₹{{ shipment.passbookEstimated ? isNaN(shipment.passbookEstimated) ? "N/A" : parseFloat(shipment.passbookEstimated).toFixed(2) : 'N/A'}}
								</span>
							</div>

							<div class="col-4 col-md-3 pt-20" v-if="planName != null">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Plan
								</span>
								<span class="fs16 lh24 text-gray-900">
									{{ planName }} 
								</span>
							</div>

							<div class="col-4 col-md-3 pt-20">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Insurance
								</span>
								<span class="fs16 lh24 text-gray-900">
									{{ shipment.isInsured ? 'Insured' : 'Uninsured' }}
								</span>
							</div>

						</div>
					</div>
					<div class="mode">
						<i
						class="icon-surface fs16 mr-16 mb-4 mb-md-0 text-gray-600 d-block d-md-inline-flex"
						></i>
						<div class="d-inline">
							<span class="fs12 lh16 text-gray-600">
								Mode Type
							</span>
							<span class="d-block fs16 lh24 fw500 text-gray-900">
								{{ staticName($store.getters.modes, shipment.mode) }}
							</span>
						</div>
					</div>
					<div class="order">
						<i class="icon-prepaid fs16 mr-16 mb-4 mb-md-0 text-gray-600"></i>
						<div class="d-inline">
							<span class="fs12 lh16 text-gray-600">
								Order Type
							</span>
							<span class="d-block fs16 lh24 fw500 text-gray-900 text-uppercase">
								{{ order.type }}
							</span>
						</div>
					</div>
					<div class="carrier d-flex flex-column justify-content-around">
						<div v-if="carrier" class="align-items-center">
							<div class="row">
								<div class="col-6 col-md">
									<img :src="require(`@/assets/images/${carrier.code}.png`)" class="pr-12 w120px" alt="" />
								</div>
								<div class="col-6 col-md">
									<span class="d-block fs20 lh24 fw500 text-gray-900">
										{{ carrier.name }}
									</span>
									<span class="fs12 lh16 text-gray-600">
										Carrier
									</span>
								</div>
							</div>
						</div>
						<div class="row pt-24 pt-md-0">
							<div class="col-6 col-md">
								<div>
									<span class="fs16 lh24 text-gray-900" v-if="tracking.expectedDeliveryDate">
										{{ tracking.expectedDeliveryDate | date }}
									</span>
									<span v-else class="fs16 lh24 text-gray-900">
										N/A
									</span>
									<span class="fs12 lh16 text-gray-600 d-block">
										Estimated Delivery
									</span>
								</div>
							</div>
							<div class="col-6 col-md">
								<div>
									<span class="fs16 lh24 text-gray-900">
										{{ tracking.awbNo? tracking.awbNo : 'N/A' }}
										
									</span>
									<span class="fs12 lh16 text-gray-600 d-block">
										Tracking No.(AWB)
									</span>
								</div>
							</div>
						</div>
						<div v-if="tracking.carrierID == 30 && tracking.taskID" class="row pt-24 pt-md-0">
							<div class="col-6 col-md">
								<div>
									<span class="fs16 lh24 text-gray-900">
										{{ tracking.taskID? tracking.taskID : 'N/A' }}
										
									</span>
									<span class="fs12 lh16 text-gray-600 d-block">
										Shypmax ID
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="customer">
						<span class="fs16 lh24 fw500">Customer Details</span>
						<div class="pt-20 d-flex">
							<div class="mr-16 mb-16 map-wrapper"></div>
							<div class="d-inline-flex flex-column">
								<span class="fs16 lh24 text-gray-900">
									{{ order.customer.name }}
								</span>
								<span class="fs14 lh16 pb-14 text-gray-600">
									{{ order.customer.contact }}
								</span>
								<span class="fs12 lh24 text-gray-600">
									Address
								</span>
								<span v-html="order.customer.address" class="fs14 lh16 text-gray-900 mw240"></span>
								<span v-if="order.customerData.email" class="fs12 pt-10 lh24 text-gray-600">
									Email
								</span>
								<span v-if="order.customerData.email" class="fs14 lh16 text-gray-900 mw240">{{order.customerData.email}}</span>
							</div>
						</div>
					</div>
					<div class="pickup">
						<span class="fs16 lh24 fw500">Pickup Details</span>
						<div class="pt-20 d-flex">
							<div class="mr-16 mb-16 map-wrapper"></div>
							<div class="d-inline-flex flex-column">
								<span class="fs16 lh24 text-gray-900">
									{{ order.pickupDetails.name }}
								</span>
								<span class="fs14 lh16 pb-14 text-gray-600">
									{{ order.pickupDetails.contact }}
								</span>
								<span class="fs12 lh24 text-gray-600">
									Address
								</span>
								<span class="fs14 lh16 text-gray-900 mw240">
									{{ order.pickupDetails.address }}
								</span>
							</div>
						</div>
					</div>
					<div class="timeline" v-if="shipEvents.length>0">
						<span class="fs16 lh24 fw500 timeline_head">Timeline</span>
						<b-button variant="tertiary" class="float-lg-right" v-if="((tracking.podDownloaded == 1 || tracking.podDownloaded == 2) && tracking.awbNo)" @click="downloadPod(tracking.podDownloaded,tracking.carrierID,tracking.awbNo)">
							<i class="icon-download mr-11"></i>Download POD</b-button> 
						<ul class="shipping-timeline">
							<li v-for="event in shipEvents" :key="event.id">
								<span class="status">
									{{ staticName(events, event.state) }}
								</span>
								<span class="meta">
									{{ event.currentLocation }}
								</span>
								<span class="meta">
									{{ event.statusTime }}
								</span>
								<span class="meta">
									{{ event.remarks }}
								</span>
							</li>
						</ul>
					</div>


					<!-- error will show when c arrier throw -->
					<div class="shipping-error-status"  v-if="shipment.hasOwnProperty('shipmentError') && isShipmentError == true && !shipEvents.length">
						<div>
							<h6>Error Reason:</h6>
							<span class="text-red">
								{{ shipment.shipmentError }}
							</span>
						</div>
					</div>

					<div class="shipping-status" :class="shipment.finalStatus.toLowerCase()">
						<span class="fs16 lh24 fw500">Shipping Status</span>
						<div class="status-dot">
							<div class="dot"></div>
							<span class="fs16 lh24 fw500 ml-30"> {{ shipment.finalStatus }}</span>
						</div>
					</div>

					<div class="package">
						<span class="fs16 lh24 d-block mb-12 fw500">Package Details</span>
						<b-table class="dark-header w-footer" :fields="computedFileds" :items="items" foot-clone responsive>
							<!-- A custom formatted header cell for field 'name' -->
							<template v-slot:head(quantity)="">
								Qty.
							</template>
							<template v-slot:head(value)="">
								<span class="d-block text-right">Value</span>
							</template>
							<!-- A custom formatted cell for field 'dimensions' -->
							<template v-slot:cell(dimensions(CM))="data">
								<div class="w120px">
									{{ data.item.length }} x
									{{ data.item.width }} x
									{{ data.item.height }}
								</div>
							</template>
							<!-- A custom formatted cell for field 'value' -->
							<template v-slot:cell(weight)="data">
								<span class="d-block">{{ data.item.weight }}</span>
							</template>

							<template v-slot:cell(SKU)="data">
								<span class="d-block">{{ data.item.sku }}</span>
							</template>

							<template v-slot:cell(value)="data">
								<span class="d-block text-right">₹{{ data.item.value }}</span>
							</template>
							<template v-slot:cell(price)="data">
								<span class="d-block">{{ isNaN(data.item.price) ? '' : (data.item && data.item.price == null) ? '0.00' : parseFloat(data.item.price).toFixed(2) }}</span>
							</template>
							<!-- Footer cells render empty -->
							<template v-slot:foot(category)="">
								<span>Total Value</span>
							</template>
							<template v-slot:foot(name)="">
								<span>Total Value</span>
							</template>
							<template v-slot:foot(sku)="">
								<span></span>
							</template>
							<template v-slot:foot(dimensions(CM))="">
								<span></span>
							</template>
							<template v-slot:foot(weight)="">
								<span></span>
							</template>
							<template v-slot:foot(quantity)="">
								<span></span>
							</template>
							<template v-slot:foot(price)="">
								<span class="d-block fw700">{{ channels[order.channel] && channels[order.channel].name == 'international' ? '$' : '₹' }}

									{{order.totalValue ? order.totalValue : isNaN(total) ? 0 : ''}}

								 <!-- {{ isNaN(total) ? 0 : order.totalValue }} -->
								</span>
							</template>
						</b-table>
					</div>

					<div class="hello" v-if="order.channel == 12">
						<span class="fs16 lh24 fw500">Additional Details</span>
						<div class="row pt-16">
							<div class="col-8 col-md-3">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									IOSS Number
								</span>
								<span class="fs16 lh24 text-gray-900">
									{{ order.iossNumber }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal
		    centered
		    v-model="isOpen"
		    title="Cancel Order"
		    id="cancelOrderModal"
		    ref="cancelShipmentModal"
		    modal-class="cancel-modal"
		  >
		    <template v-slot:modal-header="{ close }">
		      <span class="fs18 lh28 fw500">
		        Cancel Shipmnet
		      </span>
		      <b-button variant="rounded" @click="close()">
		        <i class="icon-close-variant fw400"></i>
		      </b-button>
		    </template>
		    <p class="py-10 text-gray-800">
		      Are you sure you want to cancel this shipment?
		    </p>
		    <template v-slot:modal-footer="{ close }">
		      <b-button
		        variant="secondary-modal w-sm-100 mb-12 mb-md-0"
		        @click="cancelShipment(shipment.id)"
		        class="m-0 mr-8"
		      >
		        Yes, cancel shipment
		      </b-button>
		      <b-button
		        id="btnHide"
		        variant="primary w-sm-100"
		        @click="close()"
		        class="m-0"
		      >
		        No, lead me back
		      </b-button>
		    </template>
		  </b-modal>

		<ShipmentsCancelModal ref="cancelOrderModal" />
		<ShipmentRebook :rebookData="rebookData" :rebookWarehouses="rebookWarehouses" ref="shipmentsRebookModal" />
		<ShipmentBookReverse :bookReverseAddress="bookReverseAddress" :bookReverseShipmentData="bookReverseShipmentData" ref="bookReverseModal" />
		<editShipment :editformData="editformData" ref="editShipment" />
	</div>
</template>

<script>
import vClickOutside from "v-click-outside";
import ShipmentRebook from "@/components/ShipmentRebook.vue";
import ShipmentBookReverse from "@/components/ShipmentBookReverse.vue";
import ShipmentsCancelModal from "@/components/ShipmentsCancelModal.vue";
import editShipment from  "@/components/editShipment.vue";

export default {
	name: "ShipmentsSingleItem",
	props: ['order', 'shipment', 'tracking', 'pkgDim', 'channels', 'items', 'shipEvents', 'events', 'statuses', 'planName'],
	data() {
		return {
			isOpen: this.modalOpen,
			mobileMenuToggle: true,
			orderMenuToggle: true,
			isBooking: false,
			isShipmentError: false,
			isReversing: false,
			fields: [
			{
				key: "name",
			},
			{
				key: "SKU",
			},
			{ key: "dimensions(CM)", label: "L x W x H (cm)" },
			{
				key: "weight", label: 'Weight (kg)',
			},
			{
				key: "quantity",
			},
			{
				key: "price",
			},
			],
			downloadSlipStatus: '',
			rebookData: {},
			rebookWarehouses: {},
			bookReverseAddress: {},
			bookReverseShipmentData: {},
			editformData: {},
			showeditShipment:true

		};
	},
	components: {
		ShipmentRebook,
		ShipmentBookReverse,
		ShipmentsCancelModal,
		editShipment
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	mounted() {
		this.buttonDownloadSlip();
	},

	created() {
		let errorKey = ['phone', 'mobile', 'city', 'length', 'width', 'height', 'weight', 'contact', 'greater', 'less', 'address', 'service', 'pincode', 'pin', 'warehouse','passbook'];
			for (let i of errorKey) {
				if(this.tracking.awbNo == null && this.shipment.shipmentError && this.shipment.shipmentError.toUpperCase().includes(i.toUpperCase())) {
					this.isShipmentError = true;
					break;
				}
			}
	},

	methods: {
		async editShipmentOpen(id) {
			try {
				let result = await this.axios.get(`/shipment/${id}`)

				this.editformData = result.data.shipment;

				this.$refs.editShipment.isOpen = !this.$refs.editShipment.isOpen;
				this.bottomOrderMenuToggle = true;
			} catch(e) {
				// statements
				console.log(e);
			}

		},
		goBack() {
			this.$router.go(-1)
			// this.$router.push('/shipments?tab=1&page=1&offset=25');
		},
		async clearSelected() {
			try {
				const res = await this.axios.post(`/shipment/${this.shipment.id}/cancel`)
				if(res.data.success == true) {
					this.tracking.isCancelled = 1
					this.popToast("booked", "Success!", 'Order has been canceled.');
				}else{
					this.popToast("failed", "Cancellation Failed!", res.data.message);
				}
			} catch(e) {
				console.log(e);
			}
		},
		async cancelShipment(value) {
			try {
				let response = await this.axios.post(`/shipment/${value}/cancel`)

			if(response.data.success){
				this.shipment.finalStatus = 'Cancelled / Cancelled'
				this.tracking.isCancelled = 1
				this.downloadSlipStatus = ''
				this.popToast("booked", "Cancel", "Shipment cancellation completed");
				this.isOpen = false
				setTimeout(() => { 
		          this.$router.push('/shipments?tab=1&page=1&offset=25')
		        }, 1000)
			} else {
				this.popToast("failed","Failed","Shipment cancellation failed")
			}
			} catch(e) {
				// statements
				console.error(e);
			}
			
		},
		async downloadPod(podValue, CarrierID, Awb) {
			const res = await this.axios.post('/shipment/downloadpod',{awb:Awb, carrierID: CarrierID, podVal: podValue})
			if(res.data.path) {
				let downloadAwb = null
				if (res.data.path.includes('.jpg') || res.data.path.includes('.jpeg')) {
					downloadAwb = Awb+="_pod.jpeg"
				} else {
					downloadAwb = Awb+="_pod.pdf"
				}
				this.download(res.data.path,downloadAwb);
				this.popToast("requested","Download requested","Your download should start now.");
			} else {
				this.popToast("failed","Failed","File Doesn't Exist");
			}
		},
		shipmentsCancelButton() {
			this.isOpen = !this.isOpen;
		},
		buttonDownloadSlip() {

			const shipmentStatus = ["Shipment Booked","Picked Up","In Transit","Delayed","Exception","Out for Delivery","RTO-Notified","Delivered", "Lost", "Out for Pickup", "Cancelled"]

			let trackingStatus = this.tracking.isCancelled && this.tracking.status == 0 ? 'Cancelled': this.tracking.childAwbID || this.tracking.returnOrReverse ? rtoStatus[parseInt(this.tracking.childAwbStatus)] || rtoStatus[parseInt(this.tracking.status)] : shipmentStatus[parseInt(this.tracking.status)] || 'Processed'

			let orderStatus = this.order.status == "8" ? 'Error' : this.order.status == "1" ? 'Processed' : 'Processing'

			let status = this.shipment.processed == "2" ? 'Error' : this.shipment.processed == "1" ? trackingStatus : this.shipment.processed == "4" ? 'Under-Approval' : orderStatus

			this.downloadSlipStatus = (status == "Shipment Booked") && (( this.order.type.toLowerCase() != 'reverse') || ( this.order.type.toLowerCase() == 'reverse' && this.tracking.carrierID == 4)) ? 'enabled' : 'disabled'

		},
		async rebookShipments(value) {
			this.isBooking = true
			let result = await this.axios.get(`/shipment/book/${value}/0`);
			this.rebookWarehouses = result.data.address;

			this.rebookData = result.data.shipment;

			this.openRebookModal();
			this.isBooking = false
		},
		async bookReverseShipment(value) {
			this.isReversing = true
			let result = await this.axios.get(`/shipment/book/${value}/1`);

			this.bookReverseAddress = result.data.address;

			this.bookReverseShipmentData = result.data.shipment;

			this.openBookReverseModal();
			this.isReversing = false
		},
		async shipmentDownloadButton(value) {

			this.popToast("requested", "Download requested", "Your download should start now.");

			let response = await this.axios.get(`/slip/${value}`);

			this.download(response.data.path, response.data.name)
		},
		staticName(statics, index) {
			return statics.find(i => i.value == index) ? statics.find(i => i.value == index).name: 'N/A';
		},
		openCancelModal() {
			this.$refs.cancelOrderModal.isOpen = !this.$refs.cancelOrderModal.isOpen;
			this.bottomOrderMenuToggle = true;
		},
		openRebookModal: function() {
			this.$refs.shipmentsRebookModal.isOpen = !this.$refs.shipmentsRebookModal.isOpen;
			this.bottomOrderMenuToggle = true;
		},
		openBookReverseModal: function() {
			this.$refs.bookReverseModal.isOpen = !this.$refs.bookReverseModal.isOpen;
			this.bottomOrderMenuToggle = true;
		},
		clickOutsideMenu() {
			this.orderMenuToggle = true;
		},
		clickOutsideMobileMenu() {
			this.mobileMenuToggle = true;
		},
		async downloadToast() {
			try {
				this.popToast("requested", "Download requested", "Your download should start now.");
				const res = await this.axios.get(`/slip/${this.shipment.id}`)
				this.download(res.data.path, res.data.name)
				this.orderMenuToggle = true;
				this.bottomOrderMenuToggle = true;
			} catch(e) {
				console.log(e);
			}
		}
	},
	computed: {
		computedFileds() {
			if(this.items[0].hasOwnProperty('sku')){
				return this.fields
			}else{
				this.fields[1].key = 'category'
				return this.fields
			}
		},
		total() {
			var total = 0;
			return this.items.reduce((total, item) => {
				return total + parseFloat(item.price);
			}, 0);
		},
		shippingStages() {
			return this.$store.getters.orderShippingStages;
		},
		carrier() {
			return this.$store.getters.carriers.filter(i => i.value == this.tracking.carrierID)[0]
		}
	},
};
</script>

<style lang="scss" scoped>
.shipment-grid {
	display: inline-grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	grid-gap: rem(4px);
	@include media-breakpoint-up(md) {
		width: 100%;
	}
	.shipment {
		grid-column: 1 / 4;
		grid-row: 1 / 5;

		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 1 / 3;
		}
	}
	.timeline_head {
		    top: 0.4rem;
    		position: relative;
	}
	.mode {
		display: flex;
		align-items: center;
		grid-column: 4 / 5;
		grid-row: 1 / 3;
		@include media-breakpoint-down(md) {
			grid-column: 1 / 4;
			grid-row: 3 / 5;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
			margin-top: 0;
			grid-row: 3 / 6;
		}
	}
	.order {
		display: flex;
		align-items: center;
		grid-column: 4 / 5;
		grid-row: 3 / 5;
		@include media-breakpoint-down(md) {
			grid-column: 4 / 7;
			grid-row: 3 / 5;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
			margin-top: 0;
			grid-row: 3 / 6;
		}
	}
	.carrier {
		grid-column: 5 / 7;
		grid-row: 1 / 5;
		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 6 / 10;
		}
		@include media-breakpoint-down(sm) {
			grid-column: 1 / 7;
			grid-row: 10 / 12;
		}
	}
	.shipping-timeline {
		    padding: 1.5rem 0 0 1.75rem !important; 
	}
	.customer {
		grid-column: 1 / 3;
		grid-row: 5 / 9;
		max-height: rem(304px);
		@include media-breakpoint-down(md) {
			grid-column: 4 / 7;
			grid-row: 10 / 12;
		}
		@include media-breakpoint-down(sm) {
			grid-column: 1 / 7;
			grid-row: 6 / 8;
		}
	}
	.pickup {
		grid-column: 3 / 5;
		grid-row: 5 / 9;
		max-height: rem(304px);
		@include media-breakpoint-down(md) {
			grid-column: 1 / 4;
			grid-row: 10 / 12;
		}
		@include media-breakpoint-down(sm) {
			grid-column: 1 / 7;
			grid-row: 8 / 10;
		}
	}
	.timeline {
		grid-column: 5 / 7;
		grid-row: 6 / 15;
		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 12 / 14;
		}
		@include media-breakpoint-down(sm) {
			margin-top: rem(44px);
		}
	}
	.package {
		grid-column: 1 / 5;
		grid-row: 9 / 11;
		table {
			width: calc(100% - 1px);
		}
		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 14 / 18;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: rem(16px);
		}
	}
	.hello {
		grid-column: 1 / 5;
		grid-row: 11 / 13;
		margin-bottom: rem(32px);
		table {
			width: calc(100% - 1px);
		}
		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 18 / 18;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: rem(16px);
		}
	}
	.shipping-status {
		grid-column: 5 / 7;
		grid-row: 5 / 6;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: rem(16px) rem(24px) !important;
		background-color: $light-blue;
		&.booked, &.in-transit {
			background-color: rgba($light-blue, 0.6) !important;
			.status-dot {
				span {
					color: $blue;
				}
				.dot {
					background-color: $blue;
				}
			}
		}
		&.picked-up {
			background-color: rgba($light-green, 0.2) !important;
			.status-dot {
				span {
					color: $green;
				}
				.dot {
					background-color: $green;
				}
			}
		}
		&.exception, &.delayed {
			background-color: rgba($orange, 0.2) !important;
			.status-dot {
				span {
					color: $orange;
				}
				.dot {
					background-color: $orange;
				}
			}
		}
		&.cancelled, &.lost {
			background-color: rgba($red, 0.2) !important;
			.status-dot {
				span {
					color: $red;
				}
				.dot {
					background-color: $red;
				}
			}
		}
		&.out-for-delivery {
			background-color: rgba($lilac, 0.2) !important;
			.status-dot {
				span {
					color: $lilac;
				}
				.dot {
					background-color: $lilac;
				}
			}
		}
		&.delivered {
			background-color: rgba($green, 0.2) !important;
			.status-dot {
				span {
					color: $green;
				}
				.dot {
					background-color: $green;
				}
			}
		}
		&.returned {
			background-color: rgba($gray-900, 0.2) !important;
			.status-dot {
				span {
					color: $gray-900;
				}
				.dot {
					background-color: $gray-900;
				}
			}
		}
		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 5 / 6;
			max-height: rem(40px);
		}
		@include media-breakpoint-down(sm) {
			grid-column: 1 / 7;
			grid-row: 12 / 13;
		}
	}
}

.map-wrapper {
	position: relative;
	height: rem(64px);
	width: rem(64px);
	background-color: $gray-200;
	border-radius: $border-radius;
	&:after {
		display: block;
		position: absolute;
		top: calc(50% - 10px);
		left: calc(50% - 8px);
		content: "";
		background-image: url("../assets/svg/marker-gray.svg");
		//background-image: url("../assets/svg/map-marker.svg");
		background-repeat: no-repeat;
		height: rem(32px);
		width: rem(26px);
	}
}

.shipment-grid > div {
	background: $white;
	padding: rem(24px);
	border-radius: rem(8px);
	min-width: rem(204px);
	@include media-breakpoint-down(md) {
		min-width: auto;
	}
}

.status-dot {
	position: relative;
	.dot {
		width: 15px;
		height: 15px;
		background-color: $blue;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: 5px;
	}
}
/deep/ .dark-header.w-footer > tfoot > tr > th:last-child {
	padding: 0.75rem !important;
}
.spinner-border{
	width: 1rem;
	height: 1rem;
}
.shipping-error-status {
	grid-column: 7 / 5;
	grid-row: 6 / 8;
	margin-bottom: rem(32px);
	table {
		width: calc(100% - 1px);
	}
	@include media-breakpoint-down(md) {
		grid-column: 1 / 7;
		grid-row: 14 / 18;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: rem(16px);
	}
}

</style>
