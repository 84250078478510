<template>
	<div>
		<b-modal
		ref="fullOrderEditModal"
		id="modal-tall"
		size="lg"
		title="Rebook Shipment"
		v-model="isOpen"
		modal-class="book-modal"
        :hide-footer="true"
		>
		<template v-slot:modal-header="{ close }">
			<span class="d-block fs18 lh28 fw500 mt-8">
				Edit Shipment - {{ form.orderId }}
			</span>
			<!-- Emulate built in modal header close button action -->
			<b-button size="sm" variant="rounded" @click="close()">
				<i class="icon-close-variant fs14"></i>
			</b-button>
		</template>
		<b-form @submit.prevent="saveButton('bookSave')">
			<div class="">
				<div class="row">
					<div class="col">
						<span class="fs18 lh28 fw500 d-block pb-38 pb-md-24">
							Order Information
						</span>
					</div>
				</div>

				<div class="row">
					<div class="col">
						<b-form-group
						id="warehouse-field"
						label="Warehouse"
						label-for="warehouse"
						>
						<multiselect
						id="warehouse"
						v-model="form.sellerAddressId"
						:options="editOrderWarehouses"
						label="name"
                        ref="warehouse"
						@input="Onchange(form.sellerAddressId)"
						:show-labels="false"
						track-by="name"
						></multiselect>
                        <span v-if="!form.sellerAddressId" class="fs12 text-red">Warehouse selection is required</span>
						<span v-else class="fs12 text-gray-600">{{ warehouseAddress }}</span>
					</b-form-group>
				</div>
			</div>

			<div class="row">
<!--           <div class="col-md-6">
            <b-form-group id="mode-field" label="Mode" label-for="mode">
              <multiselect
                id="mode"
                v-model="form.modeType"
                :options="modeType"
                label="name"
                :searchable="false"
                :show-labels="false"
              ></multiselect>
            </b-form-group>
        </div> -->
        <div class="col-md-6">
        	<b-form-group
        		id="orderid-field"
        		label="Order ID"
        		label-for="order-id"
        	>
        	<b-form-input
        		disabled
        		class="order_id"
        		:class="{sameOrderid : giveNeworder}"
        		id="order-id"
        		minlength="2"
          		maxlength="35"
          		required
          		pattern="[0-9a-z#A-Z_ /-]*"
          		title="Maximum 35 characters allowed"
        		placeholder="Enter order id"
        		v-model="form.orderId"
        		ref="orderId"
        	></b-form-input>
        </b-form-group>
    </div>

    <div class="col-6 col-md-6">
    	<b-form-group id="date-field" label="Date" label-for="date">
    		<date-picker
    			disabled
    			prefix-class="xmx"
    			v-model="form.orderDate"
    			ref="orderDate"
    			id="date"
    			placeholder="yyyy-mm-dd"
    		>
    		<i slot="icon-calendar" class="icon-calendar"></i>
    	</date-picker>
    </b-form-group>
</div>
</div>

<div class="row">

	<div class="col-md-6">
		<b-form-group id="type-field" label="Order Type" label-for="type">
			<multiselect
				id="type"
				v-model="form.orderType"
            	ref="orderType"
				:options="types"
				:searchable="false"
				:show-labels="false"
			></multiselect>
            <span v-if="!form.orderType" class="fs12 text-red">Order Type is required</span>
		</b-form-group>
	</div>

	<div class="col-6 col-md-6">
		<b-form-group
		id="total-value-field"
		label="Total Value"
		label-for="total-value"
		>
		<b-input-group append="₹">
			<b-input
			id="total-value"
			placeholder="0.00"
			v-model="form.totalValue"
			ref="totalValue"
			required
			min="0.1"
			step="any"
			type="number"
			></b-input>
		</b-input-group>
	</b-form-group>
</div>
</div>

<div class="row" v-if="user.choice == 0">
	<div class="col col-md-6">
		<b-form-group id="mode-field" label="Mode" label-for="weight">
			<multiselect
			id="mode"
			v-model="form.modeType"
            ref="modeType"
			:options="modes"
			placeholder="Select mode type"
			:searchable="false"
            :show-labels="false"
			track-by="name"
			label="name"
			>
		</multiselect>
	</b-form-group>
</div>
</div>

<div v-if="form.totalValue >= 50000" class="row">
	<div class="col-6">
      <b-form-group
        id="length-field"
        label="E-WayBill"
        label-for="ewaybill"
        class="wsnw"
      >
        <b-input
          id="ewaybill"
          v-model="form.ewaybill"
          type="text"
          placeholder="E-WayBill"
          required
        ></b-input>
      </b-form-group>
    </div>
</div>

<div class="row">
	<div class="col">
		<span class="fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24">
			Customer Information
		</span>
	</div>
</div>

<div class="row">
	<div class="col">
		<b-form-group
		id="customer-name-field"
		label="Name"
		label-for="customer-name"
		>
		<b-form-input
		id="customer-name"
		placeholder="Enter customer name"
		required
		v-model="form.customerName"
		ref="customerName"
        maxlength="35"
        minlength="3"
		></b-form-input>
	</b-form-group>
</div>
</div>

<div class="row">
	<div class="col-md-6">
		<b-form-group
		id="phone-field"
		label="Phone Number"
		label-for="phone-number"
		>
		<b-input-group>
			<b-form-input
			id="phone-number"
			placeholder="123 456 7890"
			type="tel"
			v-model="form.contact"
			ref="contact"
			required
			maxlength="10"
            minlength="10"
            :formatter="numbers"
            oninvalid="this.setCustomValidity('Enter valid mobile number.')"
            oninput="this.setCustomValidity('')"
			></b-form-input>
			<div class="input-group-append">
				<div class="input-group-text">
					<i class="icon-phone fs16 text-gray-600"></i>
				</div>
			</div>
		</b-input-group>
	</b-form-group>
</div>
<div class="col-md-6">
	<b-form-group id="city-field" label="City" label-for="city">
		<b-form-input 
		id="city" 
		placeholder="Enter city"
		v-model="form.customerCity"
		required
		ref="customerCity"
        maxlength="35"
		></b-form-input>
	</b-form-group>
</div>
</div>

<div class="row" v-if="this.maxLength">
	<div class="col-12 col-md-9">
		<b-form-group
		id="address-field"
		label="Full Address"
		label-for="address"
        :description="
         'Characters: ' + form.customerAddress.length + ' / ' + this.maxLength">
		<b-form-input
		id="address"
		required
		placeholder="Enter customer address"
		v-model="form.customerAddress"
		ref="customerAddress"
		:maxlength="this.maxLength"
		></b-form-input>
	</b-form-group>
</div>
<div class="col-6 col-md-3">
	<b-form-group id="pin-field" label="Pin Code" label-for="pin">
		<b-form-input
			id="pin"
			type="text"
          	maxlength="6"
          	minlength="6"
          	required
          	oninvalid="this.setCustomValidity('Enter valid pincode.')"
          	oninput="this.setCustomValidity('')"
          	:formatter="numbers"
          	placeholder="110001"
		  	v-model="form.customerPinCode"
		  	ref="customerPinCode"
		></b-form-input>
	</b-form-group>
</div>
</div>
</div>

<div class="row">
	<div class="col">
		<span class="fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24">
			Package Details
		</span>
	</div>
</div>
<div class="row">
	<div class="col">
		<b-table
		class="dark-header new-order"
		:fields="computedFields"
		:items="form.items"
		responsive
		>

		<!-- A custom formatted header cell for field 'dimensions' -->
		<template v-slot:head(dimensions)>
			<span class="underline-dashed">
				Dimensions
			</span>
			<i
			class="icon-tutorial ml-4 vam text-gray-400"
			v-b-tooltip.hover.right="'SKU Dimensions!'"
			></i>
		</template>

		<!-- A custom formatted header cell for field 'sku' -->
		<template v-slot:head(sku)>
			SKU
		</template>

		<!-- A custom formatted header cell for field 'quantity' -->
		<template v-slot:head(quantity)>
			QTY. *
		</template>

		<!-- A custom formatted cell for field 'dimensions' -->
		<template v-slot:cell(sku)="data">
			{{data.item.sku}}
		</template>

		<!-- <template v-slot:cell(selected)="{ item, field: { key }}">
			<div class="d-flex justify-content-center w-sm-50 align-items-center">
				<b-form-checkbox v-model="item.selected" @input="deSelect($event)"></b-form-checkbox>
			</div>
		</template> -->

		<template v-slot:cell(name)="data">
			<b-form-input 
				v-model="data.item.name" 
				placeholder="Product Name" 
				class="input-sm sku-name w40"
				maxlength="100">		
			</b-form-input>		
		</template>

		<template v-slot:cell(dimensions)="data">
			<div class="d-flex align-items-center">
				<b-form-input
					v-model="data.item.dimensions.length"
					placeholder="0"
					type="number"
        			min="0"
        			max="999"
        			class="small w40"
				></b-form-input>
				<span class="px-4">x</span>
				<b-form-input
					v-model="data.item.dimensions.width"
					placeholder="0"
					type="number"
        			min="0"
        			max="999"
        			class="small w40"
				></b-form-input>
				<span class="px-4">x</span>
				<b-form-input
					v-model="data.item.dimensions.height"
					placeholder="0"
					type="number"
        			min="0"
        			max="999"
        			class="small w40"
				></b-form-input>
			</div>
		</template>

		<!-- A custom formatted cell for field 'weight' -->
		<template v-slot:cell(weight)="data">
			<div class="d-flex align-items-center">
				<b-form-input
					v-model="data.item.weight"
					placeholder="0.00"
					step=".01"
					type="number"
					max="999.99"
        			min="0"
        			class="small w72"
				></b-form-input>
			</div>
		</template>

		<!-- A custom formatted cell for field 'quantity' -->
		<template v-slot:cell(quantity)="data">
			<b-form-input
				v-model="data.item.quantity"
				placeholder="0"
				type="number"
      			min="0"
      			class="small w40"
			></b-form-input>
		</template>

		<!-- A custom formatted cell for field 'value' -->
		<template v-slot:cell(value)="data">
			<div class="d-flex align-items-center">
				<b-form-input
				v-model="data.item.value"
				step=".01"
				placeholder="0.00"
				type="number"
        		min="0"
        		class="small w72"
				></b-form-input>
			</div>
		</template>
	</b-table>
</div>
</div>

<div class="row" v-if="(fields[0].key == 'sku' || fields[1].key == 'sku')">
	<div class="col">
		<span class="fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24">
			Net Dimensions
		</span>
	</div>
</div>
<div class="row" v-if="(fields[0].key == 'sku' || fields[1].key == 'sku')">
	<div class="col">
		<b-table
		v-model="form.packetdiamention"
		class="dark-header new-order"
		:fields="netfields"
		:items="form.packetdiamention"
		responsive
		>

		<!-- A custom formatted cell for field 'dimensions' -->
		<template v-slot:head(dimensions)>
			<span class="underline-dashed">
				Dimensions
			</span>
			<i
			class="icon-tutorial ml-4 vam text-gray-400"
			v-b-tooltip.hover.right="'Net Dimensions LxWxH in cm'"
			></i>
		</template>

		<template v-slot:cell(dimensions)="data">
			<div class="d-flex align-items-center">
				<span class="mr-5">L</span>
				<b-form-input
					required
					v-model="data.item.itemLength"
					placeholder="0"
					type="number"
					max="999"
        			min="1"
        			class="small w50px"
				></b-form-input>
				<span class="px-4">x</span>
				<span class="mr-5 ml-5">W</span>
				<b-form-input
					required
					v-model="data.item.itemWidth"
					placeholder="0"
					max="999"
					type="number"
        			min="1"
        			class="small w50px"
				></b-form-input>
				<span class="px-4">x</span>
				<span class="mr-5 ml-5">H</span>
				<b-form-input
					required
					v-model="data.item.itemHeight"
					placeholder="0"
					max="999"
        			type="number"
        			min="1"
				class="small w50px"
				></b-form-input>
			</div>
		</template>

		<!-- A custom formatted cell for field 'weight' -->
		<template v-slot:cell(weight)="data">
			<div class="d-flex align-items-center">
				<b-form-input
					required
					step=".01"
        			type="number"
        			min="0.01"
					placeholder="0.00"
					max="999.99"
					v-model="data.item.itemWeight"
					class="small w72 net-weight"
				></b-form-input>
				<span class="ml-4">
					kg
				</span>
			</div>
		</template>
      </b-table>
  </div>
</div>


<b-button id="btnHide" variant="primary" type="submit" :disabled="disableSave" class="m-0 mb-20">SAVE & BOOK</b-button>


<EditCalcPricing @openModal="openPricingModal" :editShipment.sync="editShipment" :formInfo.sync="calcForm" :choiceData.sync="calcData" ref="editcalcpricing" />
</b-form>
</b-modal>
</div>
</template>

<script>
import { VMoney } from "v-money";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import EditCalcPricing from "@/components/EditCalcPricing.vue"
import { mapState } from "vuex";

export default {
	name: "ShipmentEdit",
	props: ['modalOpen', 'editformData'],
	data() {
		return {
			isOpen: this.modalOpen,
			date: null,
			value: null,
			allSelected: false,
			importedID: null,
			form: {},
			dialogData: {},
			warehouseName: null,
			option: [],
			splitOrder: false,
			checkbox:true,
			disableNet:false,
      // modeType: [],
      calcData:[],
      calcForm:[],
      editShipment:false,
      editOrderWarehouses: [],
      orderType: "",
      disableCreate:false,
      totalValue: "",
      maxLength: null,
      customerName: "",
      customerPinCode: "",
      contact: "",
      customerAddress: "",
      disableSave:false,
      choiceMode: '',
      giveNeworder:false,
      types: ["PREPAID", "COD", "REVERSE"],
      items: [],
      warehouseAddress:'',
      fields: [
      {
      	key: "sku",
      	sortable: true,
      	class: "pl-0",
      },
      {
      	key: "name",
      	label:"Name *",
      	sortable: true,
      },
      { key: "dimensions", label: "Dimensions" },
      {
      	key: "weight",
      	label: "Weight (kg)",
      	sortable: true,
      },
      {
      	key: "quantity",
      	label:"Quantity *",
      	sortable: true,
      },
      {
      	key: "value",
      	label: "Value (₹)",
      	sortable: true,
      	class: "pr-0",
      },
      ],
      netfields: [
      { key: "dimensions", label: "Dimensions" },
      {
      	key: "weight",
      	sortable: true,
      }
      ],
      money: {
      	decimal: ",",
      	thousands: ".",
      	precision: 2,
      	masked: false,
      },
  };
},
components: {
	DatePicker,
	Multiselect,
	EditCalcPricing
},
directives: { money: VMoney },
watch: {
	selectedRows: function() {
		if (this.selectedRows.length == this.items.length) {
			this.allSelected = true;
		}
	},
	editformData: function(newValue) {
		if(newValue) {
			this.editAndRebook(newValue)
			this.maxLength = 140
		}
	},
	isOpen: function(newValue) {
		if(newValue) {
			this.disableSave = false
      		this.giveNeworder = false
		}
	}
},
mounted() {
      // this.staticData();
      this.editWarehouses();
  },
  computed:{
  	modes() {

  		let modes = undefined;
  		// MPS
  		if (this.form.channel == 9) {
  			modes = this.$store.getters.mode.mps;
  		}
  		// International
  		else if (this.form.channel == 12) {
  			modes = this.$store.getters.mode.intl;
  		}
  		// B2B
  		else if (this.form.channel == 37) {
  			modes = this.$store.getters.mode.b2b;
  		}
  		else {
  			modes = this.$store.getters.mode.domestic;
  		}

  		return modes;
  	},
  	computedFields() {
  		if (this.form.items && this.form.items.length == 1 && this.fields.length == 7) {
  			// this.fields.splice(0,1)
  			let temp = this.fields
  			temp.splice(0,1)
  			return temp
  		} else {
  			return this.fields
  		}
  	},
  	...mapState(["user"])
  },
  methods: {
  	Onchange(value) {
        if(value) {
            const res = this.form.addresses.find(item => item.id == value.id )
            this.warehouseAddress = res.address
        }
  	},
  	openPricingModal: function() {
  		this.$refs.editcalcpricing.isOpen = !this.$refs
  		.editcalcpricing.isOpen;
  	},
  	deSelect(value) {
  		let abc = []
  		abc = this.form.items.filter(i => i.selected == true)
  		if (abc.length < this.form.items.length) {
  			if (abc.length == 0) {
	  			this.disableCreate = true;
	  		}
	  		else {
	  			this.disableCreate = false;
	  		}
  			this.disableSave = true
  		} 
  		else {
  			this.disableCreate = false;
  			this.disableSave = false
  		}
  	},
  	async editWarehouses() {
        this.editOrderWarehouses = this.$store.getters.warehouses.map((i) => {
            return {
                name: i.name, 
                id: i.id,
                address: i.address,
                $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
            };
        })

  	},
  	toggleAllRows() {
  		if (this.allSelected == false) {
  			this.items.forEach((item) => {
  				this.$set(item, "selected", true);
  				this.allSelected = true;
  			});
  		} else {
  			this.selectedRows.forEach((item) => {
  				this.$delete(item, "selected");
  				this.allSelected = false;
  			});
  		}
  	},
  	isvalidateZeroOrBlank(input = "") {
  		if (String(input).trim() == 0 || input == 0) {
  			return true;
  		}
  		return false;
  	},
  	async editAndRebook(value) {

  		if(value.address) {
	  		const res = value.address.find(item => item.id == value.order.warehouseID )
	  		this.warehouseAddress = res.address
  		}

  		let editableData = {

  			orderId:value.order.importedID,
  			shipmentId: value.shipment.id,
  			sellerAddressId: {
  				id: value.order.warehouseID,
  				name: value.order.pickupDetails.name,
  			},
  			tableID:value.order.id,
  			orderType: value.order.type,
  			orderDate: value.order.date,
  			totalValue: value.order.totalValue,
  			customerName: value.order.customerData.name,
  			customerPinCode: value.order.customerData.pin,
  			customerCity: value.order.customerData.city,
  			contact: value.order.customerData.contact,
  			customerAddress: value.order.customer.address,
  			packetdiamention: (Object.entries(value.order.packetdiamention).length == 0 && Object(value.order.packageDetails).length > 1) ? [ { 'itemWidth': null, 'itemHeight': null, 'itemLength': null, 'itemWeight': null }] : [value.order.packetdiamention],
  			addresses: value.address,
  			mode:value.order.mode,
  			channel:value.order.channel,

  			items: value.shipment.items.map(item => {
  				return {
  					fieldRequired: Object.keys(value.order.packetdiamention).length == 0 ? true : false,
  					selected : true,
  					sku: item.sku,
  					name: item.itemName,
  					dimensions: { width: item.itemWidth, height: item.itemHeight, length: item.itemLength },
  					weight: item.itemWeight,
  					quantity: item.quantity,
  					value: item.price,
  					modeType:item.modeType,
  					hsn:item.hsn ? item.hsn : 0,
  					taxRate : item.taxRate ? item.taxRate : 0,
  					discount : item.discount ? item.discount : 0 
  				}
  			})
  		}



  		if(this.user.choice !=1 ) {
  			let mode = this.modes.find(item => item.value == editableData.mode);
  			if (mode && mode.value && mode.name) {
  				editableData.modeType = {
  					value: mode.value,
  					name: mode.name
  				};
  			} else {
  				editableData.modeType = {
  					value: "0",
  					name: "Select Option"
  				};
  			}
  		}

  		editableData.orderType = editableData.orderType.toUpperCase()
  		editableData.orderDate = new Date(editableData.orderDate)

  		if(editableData.items.length) {
  			if(editableData.items.length > 1) {

  				editableData['oldlength'] = []
  				editableData['oldwidth'] = []
  				editableData['oldheight'] = []
  				editableData['oldweight'] = []
  				editableData['oldquantity'] = []
  				editableData['oldprice'] = []
  				editableData['olditemName'] = []

  				this.splitOrder = true

  				editableData.items.forEach(value => editableData['oldlength'].push(value.dimensions.length))
  				editableData.items.forEach(value => editableData['oldwidth'].push(value.dimensions.width))
  				editableData.items.forEach(value => editableData['oldheight'].push(value.dimensions.height))
  				editableData.items.forEach(value => editableData['oldquantity'].push(value.quantity))
  				editableData.items.forEach(value => editableData['oldprice'].push(value.value))
  				editableData.items.forEach(value => editableData['oldweight'].push(value.weight))
  				editableData.items.forEach(value => editableData['olditemName'].push(value.name))

  			} else {
  				editableData['oldlength'] = editableData.items[0].dimensions.length
  				editableData['oldwidth'] = editableData.items[0].dimensions.width
  				editableData['oldheight'] = editableData.items[0].dimensions.height
  				editableData['oldweight'] = editableData.items[0].weight
  				editableData['oldquantity'] = editableData.items[0].quantity
  				editableData['oldprice'] = editableData.items[0].value
  				editableData['olditemName'] = editableData.items[0].name
  				this.splitOrder = false
  			}
  		}

  		editableData['totalWeight'] = 0;
  		editableData['packageLength'] = 0;
  		editableData['packageWidth'] = 0;
  		editableData['packageHeight'] = 0;

  		this.choiceMode = editableData.mode

  		this.form = editableData;
  		if(Object.keys(this.form.packetdiamention[0]) == 0) {
  			this.disableNet = true
  		} else {
  			this.disableNet = false
  		}
  	},

  	hideModal() {
  		this.$bvModal.hide("modal-tall");
  	},
    // Submit button
    async saveButton(type) {
        if(!this.form.sellerAddressId) {
            this.$refs.warehouse.$el.focus();
            return false
        }
        if(!this.form.orderId) {
            this.$refs.orderId.$el.focus();
            return false
        }
        if(!this.form.orderDate) {
            this.$refs.orderDate.$el.focus();
            return false
        }
        if(!this.form.orderType) {
            this.$refs.orderType.$el.focus();
            return false
        }
        if(this.user.choice == 0 && this.form.modeType == null) {
            this.$refs.modeType.$el.focus();
            return false
        }
        if(!this.form.totalValue) {
            this.$refs.totalValue.$el.focus();
            return false
        }
        if(!this.form.customerName) {
            this.$refs.customerName.$el.focus();
            return false
        }
        if(!this.form.contact) {
            this.$refs.contact.$el.focus();
            return false
        }
        if(!this.form.customerCity) {
            this.$refs.customerCity.$el.focus();
            return false
        }
        if(!this.form.customerAddress) {
            this.$refs.customerAddress.$el.focus();
            return false
        }
        if(!this.form.customerPinCode) {
            this.$refs.customerPinCode.$el.focus();
            return false
        }

        
    	this.form.items.forEach(value => this.form['totalWeight'] += parseInt(value.weight))

    	let wrongDimension = false;

    	let netDimension = {};

    	Object.keys(this.form.packetdiamention[0]).forEach((element, index) => {

    		if (this.form.packetdiamention[0][element] <= 0 || String(this.form.packetdiamention[0][element]).trim() == '') {
    			wrongDimension = true;
    		}

    		netDimension[element] = this.form.packetdiamention[0][element];

    	});

    	if (wrongDimension) {
    		this.popToast("failed", "Failed", "Please Fill Net Dimension Details");
    		return;
    	}

    	if (Object.keys(netDimension).length) {
    		this.form['netDimension'] = netDimension
    	}


    	// if(this.form.items.length) {
    	// 	if(this.form.items.length > 1) {

    	// 		this.form['length'] = []
    	// 		this.form['width'] = []
    	// 		this.form['height'] = []
    	// 		this.form['weight'] = []
    	// 		this.form['quantity'] = []
    	// 		this.form['price'] = []
    	// 		this.form['itemName'] = []
    	// 		this.form['packageWeight'] = 0

    	// 		this.form.items.forEach(value => this.form['packageWeight'] += parseInt(value.weight))
    	// 		this.form.items.forEach(value => this.form['length'].push(value.dimensions.length))
    	// 		this.form.items.forEach(value => this.form['width'].push(value.dimensions.width))
    	// 		this.form.items.forEach(value => this.form['height'].push(value.dimensions.height))
    	// 		this.form.items.forEach(value => this.form['quantity'].push(value.quantity))
    	// 		this.form.items.forEach(value => this.form['price'].push(value.value))
    	// 		this.form.items.forEach(value => this.form['weight'].push(value.weight))
    	// 		this.form.items.forEach(value => this.form['itemName'].push(value.name))
    	// 	}
    	// 	else if(this.form.items[0].name){
    	// 		this.form['packageLength'] = this.form.items[0].dimensions.length
    	// 		this.form['packageWidth'] = this.form.items[0].dimensions.width
    	// 		this.form['packageHeight'] = this.form.items[0].dimensions.height
    	// 		this.form['packageWeight'] = this.form.items[0].weight
    	// 		this.form['length'] = this.form.items[0].dimensions.length
    	// 		this.form['width'] = this.form.items[0].dimensions.width
    	// 		this.form['height'] = this.form.items[0].dimensions.height
    	// 		this.form['weight'] = this.form.items[0].weight
    	// 		this.form['quantity'] = this.form.items[0].quantity
    	// 		this.form['price'] = this.form.items[0].value
    	// 		this.form['itemName'] = this.form.items[0].name
    	// 	} else {}
    	// }
    
    	if (this.form.sellerAddressId.id) {

	      	let formItemData = Object.assign({}, this.form);

    		// For multiple items
    		// if (!Array.isArray(formItemData['itemName'])) {
    		// 	console.log('worlking')

    		// 	Object.keys(formItemData).forEach( function(element, index) {
    		// 		formItemData[element] = [formItemData[element]];
    		// 	});
    		// }

    		let wrongPackage = false;

    		// return

    		if (Object.keys(formItemData.items).length > 1) {

    			formItemData.items.forEach( (element, index) => {

	    			let isSelected = true;

	    			if (isSelected) {

		    			let itemName = element
		    			let length = element['length'] || element['itemLength'];
		    			let width = element['width'] || element['itemLength'];
		    			let height = element['height'] || element['itemHeight'];
		    			let weight = element['weight'] || element['itemWeight'];
		    			let quantity = element['quantity']
		    			let price = element['price'];
		    			if (this.isvalidateZeroOrBlank(quantity) || (itemName.hasOwnProperty('name') && this.isvalidateZeroOrBlank(itemName.name)) || (itemName.hasOwnProperty('itemName') && this.isvalidateZeroOrBlank(itemName.itemName)))   {
		    				wrongPackage = true;
		    			}
	    			}
	    		});
    		} else {

    			if(formItemData.items[0].hasOwnProperty('name') && this.isvalidateZeroOrBlank(formItemData.items[0].name) || (formItemData.items[0].hasOwnProperty('itemName') && this.isvalidateZeroOrBlank(formItemData.items[0].itemName)) || this.isvalidateZeroOrBlank(formItemData.items[0].quantity)) {
    						wrongPackage = true;
    			}
    		}

    		if (wrongPackage) {
	    		this.popToast("failed", "Failed", "All Package Details Fields * are Mandatory");
	    		return;
    		}

        }
        // aage chalaana h toh niche return hataao
        // return;

        if (this.user.choice == 1) {
        	this.form['modeType'] = 'save'
        }  

        if (this.user.choice == 1) {
        	this.form.modeType = this.choiceMode
        }

	//Book and Save the Order
    if (type == 'bookSave') {  

       	let calData = Object.assign({}, this.form)

       	calData.orderType = calData.orderType.toLowerCase()
        // changing address details
        calData.addresses.filter(i => {
        	if(i.approved && i.id == calData.sellerAddressId.id) {
        		calData['originPin'] = i.pincode,
        		calData['originCity'] = i.city
        	}
        })
        delete calData.addresses

        const { items } = calData;

        items.forEach((item)=>{

        	if (item.dimensions) {
        		const { length, width, height } = item.dimensions;
	        	item.itemLength = length;
	        	item.itemWidth = width;
	        	item.itemHeight = height;
	        	item['price'] = item.value;
	        	item['itemName'] = item.name
	        	item['itemWeight'] = item.weight
	        	item.discount == 0 ? delete item.discount : '';
	        	item.hsn == 0 ? delete item.hsn : '';
	        	item.taxRate == 0 ? delete item.taxRate : '';
	        	delete(item.dimensions);
	        	delete(item.value);
	        	delete(item.name);
	        	delete(item.weight);
	        	delete(item.fieldRequired);
	        	delete(item.selected)
        	}

        	
        });

        calData.packetdiamention = Object.assign({},calData.netDimension)

		if(this.user.choice == 1 ) {

	        	calData.packageWeight = calData.packetdiamention.itemWeight;
	            calData.packageLength = calData.packetdiamention.itemLength;
	            calData.packageWidth = calData.packetdiamention.itemWidth;
	            calData.packageHeight = calData.packetdiamention.itemHeight;


	        	try {
	        		const res = await this.axios.post('/choice/getchoicedata',calData)
	        		if (res.data.success) {
	        			this.editShipment = true
	        			this.calcForm = calData
	        			this.calcData = res.data.choicepricing
	        			this.openPricingModal();
	        		} else {
	        			this.popToast("failed","Failed",`Service not available from ${calData.originPin} to ${calData.customerPinCode}. Please check the serviceability or enable other couriers.`);
	        		}
	        	} catch(e) {
	        		this.popToast("failed","Failed","Data Fetching Failed!! Please Try Again");
	        	}
	        }
		 else {

		 	calData['warehouse'] = calData.sellerAddressId.id
        	calData.modeType = calData.modeType.value
        	calData['pincode'] = calData.customerPinCode
			try {

              let result = await this.axios.put(`/shipment/edit/${calData.shipmentId}`, calData);

              if (result.data.success) {
                  this.popToast("booked", "Success!", result.data.message);

                setTimeout(() => { 
                  this.$router.push('/shipments'); 
                }, 1000)
              } 
              else {
                this.popToast("failed", "Failed!", result.data.message);
              }
              this.isOpen = false;
              
	          }
	          catch(error) {
	          	console.log(error)
	              this.popToast("failed", "Failed!", "Booking Failed!! Please Try Again Later");
	          }
		}

        // if(this.user.choice == 1 ) {

        // 	calData.packageWeight = calData.packetdiamention.itemWeight;
        //     calData.packageLength = calData.packetdiamention.itemLength;
        //     calData.packageWidth = calData.packetdiamention.itemWidth;
        //     calData.packageHeight = calData.packetdiamention.itemHeight;

        //     console.log('76796796979867hello sudhan')

        // 	try {
        // 		const res = await this.axios.put(`/shipment/edit/${calData.shipmentId}`,calData)
        // 		console.log(res)
        // 		if (res.data.success) {
        // 			this.calcForm = calData
        // 			this.calcData = res.data.choicepricing
        // 			this.openPricingModal();
        // 		} else {
        // 			this.popToast("failed","Failed",`Service not available from ${calData.originPin} to ${calData.customerPinCode}. Please check the serviceability or enable other couriers.`);
        // 		}
        // 	} catch(e) {
        // 		this.popToast("failed","Failed","Data Fetching Failed!! Please Try Again");
        // 	}
        // } else {
        // 	console.log('76796796979867hello bhavesh')
        	// calData['bookAuto'] = 'true'
        	// calData.sellerAddressId = calData.sellerAddressId.id
        	// calData.modeType = calData.modeType.value
        	// try {
        	// 	const res = await this.axios.post(`/order/edit/${calData.tableID}`,calData)
        	// 	if (res.data.success) {
        	// 		this.popToast("booked", "Success!", `Order with name ${calData.orderId} booked Successfully`);
        	// 		setTimeout(() => { 
        	// 			this.$router.push('/shipments'); 
        	// 		}, 2000)
        	// 	} else {
        	// 		this.popToast("failed", "Failed!", res.data.message);
        	// 	}
        	// } catch(e) {
        	// 	this.popToast("failed", "Failed!", "Booking Failed!! Please Try Again Later");
        	// }
        // }
        
    } else {

    }       
},
},
};
</script>

<style type="text/css" scoped="">
.sameOrderid {
	border: 2px solid red !important;
}
/deep/ .sku-name {
    width: 8rem !important;	
}

/deep/ .modal-content {
	width: max-content !important;
}

/deep/ .xmx-input:disabled {
	color: black !important;
    background-color: #e3e7ee !important;
}
</style>
