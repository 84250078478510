var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid pt-64 pt-md-80 pb-80" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-8 col-md-6 pt-16 d-flex align-items-center" },
          [
            _c(
              "b-button",
              { attrs: { variant: "icon-sm" }, on: { click: _vm.goBack } },
              [_c("i", { staticClass: "icon-previous fs9" })]
            ),
            _c("h2", { staticClass: "lh32 ml-8" }, [
              _vm._v(" OrderID " + _vm._s(_vm.order.importedID) + " ")
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4 col-md-6 pt-16 text-right" },
          [
            _vm.downloadSlipStatus == "enabled"
              ? _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-block mr-8",
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.shipmentDownloadButton(_vm.shipment.id)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "icon-download mr-10 fs16" }),
                    _vm._v(" Download ")
                  ]
                )
              : _vm._e(),
            _vm.shipment.finalStatus == "Error" &&
            (_vm.tracking.awbNo == null ||
              _vm.tracking.awbNo == "" ||
              _vm.tracking.awbNo == "null") &&
            _vm.order.channel != 9 &&
            _vm.order.channel != 12 &&
            _vm.order.channel != 37 &&
            _vm.order.channel != 1 &&
            _vm.showeditShipment
              ? _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-block mr-8",
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.editShipmentOpen(_vm.shipment.id)
                      }
                    }
                  },
                  [_vm._v(" Edit Shipment ")]
                )
              : _vm._e(),
            _vm.order.channel != 9 &&
            _vm.order.channel != 12 &&
            _vm.order.channel != 37
              ? _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-block mr-8",
                    attrs: { variant: "primary", disabled: _vm.isBooking },
                    on: {
                      click: function($event) {
                        return _vm.rebookShipments(_vm.shipment.id)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "icon-rebook mr-10 fs16" }),
                    _c(
                      "span",
                      { staticClass: "fs14 lh24" },
                      [
                        _vm._v(" Rebook "),
                        _vm.isBooking
                          ? _c("b-spinner", { staticClass: "ml-10" })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.order.channel != 9 &&
            _vm.order.channel != 12 &&
            _vm.order.type.toLowerCase() != "reverse" &&
            _vm.order.channel != 37
              ? _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-block mr-8",
                    attrs: { disabled: _vm.isReversing, variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.bookReverseShipment(_vm.shipment.id)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "icon-book-reverse mr-10 fs16" }),
                    _c(
                      "span",
                      { staticClass: "fs14 lh24" },
                      [
                        _vm._v(" Book - Reverse "),
                        _vm.isReversing
                          ? _c("b-spinner", { staticClass: "ml-10" })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.tracking.status == 0 && _vm.tracking.isCancelled == false
              ? _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-block mr-8",
                    attrs: { variant: "primary" },
                    on: { click: _vm.shipmentsCancelButton }
                  },
                  [
                    _c("i", { staticClass: "icon-cancel mr-10 fs16" }),
                    _c("span", { staticClass: "fs14 lh24" }, [
                      _vm._v(" Cancel ")
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.clickOutsideMobileMenu,
                    expression: "clickOutsideMobileMenu"
                  }
                ],
                staticClass:
                  "order-menu order-menu__mobile d-sm-inline-block d-md-none bottom"
              },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        _vm.mobileMenuToggle = !_vm.mobileMenuToggle
                      }
                    }
                  },
                  [_c("i", { staticClass: "icon-more fs16" })]
                ),
                _c(
                  "ul",
                  {
                    staticClass: "order-menu__list",
                    class: {
                      "order-menu__list--collapsed": _vm.mobileMenuToggle
                    }
                  },
                  [
                    _vm.order.channel != 9 &&
                    _vm.order.channel != 12 &&
                    _vm.order.channel != 37
                      ? _c(
                          "li",
                          { attrs: { disabled: _vm.isBooking } },
                          [
                            _c(
                              "b-link",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.rebookShipments(_vm.shipment.id)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-rebook fs20 pr-12 text-blue"
                                }),
                                _c(
                                  "span",
                                  { staticClass: "fs14 lh24" },
                                  [
                                    _vm._v(" Rebook "),
                                    _vm.isBooking
                                      ? _c("b-spinner", {
                                          staticClass: "ml-10"
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.order.channel != 9 &&
                    _vm.order.channel != 12 &&
                    _vm.order.type.toLowerCase() != "reverse" &&
                    _vm.order.channel != 37
                      ? _c(
                          "li",
                          { attrs: { disabled: _vm.isReversing } },
                          [
                            _c(
                              "b-link",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.bookReverseShipment(
                                      _vm.shipment.id
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-book-reverse fs20 pr-12 text-blue"
                                }),
                                _c(
                                  "span",
                                  { staticClass: "fs14 lh24" },
                                  [
                                    _vm._v(" Book - Reverse "),
                                    _vm.isReversing
                                      ? _c("b-spinner", {
                                          staticClass: "ml-10"
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.tracking.status == 0 &&
                    _vm.tracking.isCancelled == false
                      ? _c(
                          "li",
                          [
                            _c(
                              "b-link",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.shipmentsCancelButton(
                                      _vm.shipment.id
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-cancel fs20 pr-12 text-blue"
                                }),
                                _c("span", { staticClass: "fs14 lh24" }, [
                                  _vm._v(" Cancel ")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.downloadSlipStatus == "enabled"
                      ? _c(
                          "li",
                          [
                            _c(
                              "b-link",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.shipmentDownloadButton(
                                      _vm.shipment.id
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-download fs20 pr-12 text-blue"
                                }),
                                _c("span", { staticClass: "fs14 lh24" }, [
                                  _vm._v(" Download Order ")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row pt-16" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "shipment-grid" }, [
            _c("div", { staticClass: "shipment" }, [
              _c("span", { staticClass: "fs16 lh24 fw500" }, [
                _vm._v("Shipment Details")
              ]),
              _c("div", { staticClass: "row pt-16" }, [
                _c("div", { staticClass: "col-8 col-md-3" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                    },
                    [_vm._v(" Booking Date ")]
                  ),
                  _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("date")(_vm.tracking.created)) +
                        ", " +
                        _vm._s(_vm._f("time")(_vm.tracking.created)) +
                        " "
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-4 col-md-3" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                    },
                    [_vm._v(" Shipment ID ")]
                  ),
                  _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                    _vm._v(" " + _vm._s(_vm.shipment.id) + " ")
                  ])
                ]),
                _c("div", { staticClass: "col-8 col-md-3 mb-space" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                    },
                    [_vm._v(" Manifest ID ")]
                  ),
                  _vm.shipment.manifestID
                    ? _c(
                        "span",
                        {
                          staticClass: "fs16 lh24 cp text-blue",
                          on: {
                            click: function($event) {
                              return _vm.$router.push(
                                "/shipments?manifestID=" +
                                  _vm.shipment.manifestID
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.shipment.manifestID
                                  ? _vm.shipment.manifestID
                                  : "N/A"
                              ) +
                              " "
                          )
                        ]
                      )
                    : _c("span", { staticClass: "fs16 lh24" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.shipment.manifestID
                                ? _vm.shipment.manifestID
                                : "N/A"
                            ) +
                            " "
                        )
                      ])
                ]),
                _c("div", { staticClass: "col-4 col-md-3 mb-space" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                    },
                    [_vm._v(" Remittance Date ")]
                  ),
                  _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.shipment.remittanceDate ? "Yes" : "N/A") +
                        " "
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-8 col-md-3 pt-20" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                    },
                    [_vm._v(" Channel ")]
                  ),
                  _vm.order.channel > -1
                    ? _c("span", [
                        _vm.channels[_vm.order.channel].name == "MPS" ||
                        _vm.channels[_vm.order.channel].name == "API"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("uppercase")(
                                      _vm.channels[_vm.order.channel].name
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _c(
                              "span",
                              { staticClass: "fs16 lh24 text-gray-900" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("capitalize")(
                                        _vm._f("lowercase")(
                                          _vm.channels[_vm.order.channel].name
                                        )
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                      ])
                    : _c("span", [_vm._v(" N/A ")])
                ]),
                _c("div", { staticClass: "col-4 col-md-3 pt-20" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                    },
                    [_vm._v(" Estimated Amount ")]
                  ),
                  _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                    _vm._v(
                      " ₹" +
                        _vm._s(
                          _vm.shipment.passbookEstimated
                            ? isNaN(_vm.shipment.passbookEstimated)
                              ? "N/A"
                              : parseFloat(
                                  _vm.shipment.passbookEstimated
                                ).toFixed(2)
                            : "N/A"
                        ) +
                        " "
                    )
                  ])
                ]),
                _vm.planName != null
                  ? _c("div", { staticClass: "col-4 col-md-3 pt-20" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                        },
                        [_vm._v(" Plan ")]
                      ),
                      _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                        _vm._v(" " + _vm._s(_vm.planName) + " ")
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-4 col-md-3 pt-20" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                    },
                    [_vm._v(" Insurance ")]
                  ),
                  _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.shipment.isInsured ? "Insured" : "Uninsured"
                        ) +
                        " "
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "mode" }, [
              _c("i", {
                staticClass:
                  "icon-surface fs16 mr-16 mb-4 mb-md-0 text-gray-600 d-block d-md-inline-flex"
              }),
              _c("div", { staticClass: "d-inline" }, [
                _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                  _vm._v(" Mode Type ")
                ]),
                _c(
                  "span",
                  { staticClass: "d-block fs16 lh24 fw500 text-gray-900" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.staticName(
                            _vm.$store.getters.modes,
                            _vm.shipment.mode
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "order" }, [
              _c("i", {
                staticClass:
                  "icon-prepaid fs16 mr-16 mb-4 mb-md-0 text-gray-600"
              }),
              _c("div", { staticClass: "d-inline" }, [
                _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                  _vm._v(" Order Type ")
                ]),
                _c(
                  "span",
                  {
                    staticClass:
                      "d-block fs16 lh24 fw500 text-gray-900 text-uppercase"
                  },
                  [_vm._v(" " + _vm._s(_vm.order.type) + " ")]
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "carrier d-flex flex-column justify-content-around"
              },
              [
                _vm.carrier
                  ? _c("div", { staticClass: "align-items-center" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6 col-md" }, [
                          _c("img", {
                            staticClass: "pr-12 w120px",
                            attrs: {
                              src: require("@/assets/images/" +
                                _vm.carrier.code +
                                ".png"),
                              alt: ""
                            }
                          })
                        ]),
                        _c("div", { staticClass: "col-6 col-md" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "d-block fs20 lh24 fw500 text-gray-900"
                            },
                            [_vm._v(" " + _vm._s(_vm.carrier.name) + " ")]
                          ),
                          _c(
                            "span",
                            { staticClass: "fs12 lh16 text-gray-600" },
                            [_vm._v(" Carrier ")]
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row pt-24 pt-md-0" }, [
                  _c("div", { staticClass: "col-6 col-md" }, [
                    _c("div", [
                      _vm.tracking.expectedDeliveryDate
                        ? _c(
                            "span",
                            { staticClass: "fs16 lh24 text-gray-900" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.tracking.expectedDeliveryDate
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _c(
                            "span",
                            { staticClass: "fs16 lh24 text-gray-900" },
                            [_vm._v(" N/A ")]
                          ),
                      _c(
                        "span",
                        { staticClass: "fs12 lh16 text-gray-600 d-block" },
                        [_vm._v(" Estimated Delivery ")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-6 col-md" }, [
                    _c("div", [
                      _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tracking.awbNo ? _vm.tracking.awbNo : "N/A"
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "span",
                        { staticClass: "fs12 lh16 text-gray-600 d-block" },
                        [_vm._v(" Tracking No.(AWB) ")]
                      )
                    ])
                  ])
                ]),
                _vm.tracking.carrierID == 30 && _vm.tracking.taskID
                  ? _c("div", { staticClass: "row pt-24 pt-md-0" }, [
                      _c("div", { staticClass: "col-6 col-md" }, [
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "fs16 lh24 text-gray-900" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tracking.taskID
                                      ? _vm.tracking.taskID
                                      : "N/A"
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "span",
                            { staticClass: "fs12 lh16 text-gray-600 d-block" },
                            [_vm._v(" Shypmax ID ")]
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]
            ),
            _c("div", { staticClass: "customer" }, [
              _c("span", { staticClass: "fs16 lh24 fw500" }, [
                _vm._v("Customer Details")
              ]),
              _c("div", { staticClass: "pt-20 d-flex" }, [
                _c("div", { staticClass: "mr-16 mb-16 map-wrapper" }),
                _c("div", { staticClass: "d-inline-flex flex-column" }, [
                  _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                    _vm._v(" " + _vm._s(_vm.order.customer.name) + " ")
                  ]),
                  _c("span", { staticClass: "fs14 lh16 pb-14 text-gray-600" }, [
                    _vm._v(" " + _vm._s(_vm.order.customer.contact) + " ")
                  ]),
                  _c("span", { staticClass: "fs12 lh24 text-gray-600" }, [
                    _vm._v(" Address ")
                  ]),
                  _c("span", {
                    staticClass: "fs14 lh16 text-gray-900 mw240",
                    domProps: { innerHTML: _vm._s(_vm.order.customer.address) }
                  }),
                  _vm.order.customerData.email
                    ? _c(
                        "span",
                        { staticClass: "fs12 pt-10 lh24 text-gray-600" },
                        [_vm._v(" Email ")]
                      )
                    : _vm._e(),
                  _vm.order.customerData.email
                    ? _c(
                        "span",
                        { staticClass: "fs14 lh16 text-gray-900 mw240" },
                        [_vm._v(_vm._s(_vm.order.customerData.email))]
                      )
                    : _vm._e()
                ])
              ])
            ]),
            _c("div", { staticClass: "pickup" }, [
              _c("span", { staticClass: "fs16 lh24 fw500" }, [
                _vm._v("Pickup Details")
              ]),
              _c("div", { staticClass: "pt-20 d-flex" }, [
                _c("div", { staticClass: "mr-16 mb-16 map-wrapper" }),
                _c("div", { staticClass: "d-inline-flex flex-column" }, [
                  _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                    _vm._v(" " + _vm._s(_vm.order.pickupDetails.name) + " ")
                  ]),
                  _c("span", { staticClass: "fs14 lh16 pb-14 text-gray-600" }, [
                    _vm._v(" " + _vm._s(_vm.order.pickupDetails.contact) + " ")
                  ]),
                  _c("span", { staticClass: "fs12 lh24 text-gray-600" }, [
                    _vm._v(" Address ")
                  ]),
                  _c("span", { staticClass: "fs14 lh16 text-gray-900 mw240" }, [
                    _vm._v(" " + _vm._s(_vm.order.pickupDetails.address) + " ")
                  ])
                ])
              ])
            ]),
            _vm.shipEvents.length > 0
              ? _c(
                  "div",
                  { staticClass: "timeline" },
                  [
                    _c(
                      "span",
                      { staticClass: "fs16 lh24 fw500 timeline_head" },
                      [_vm._v("Timeline")]
                    ),
                    (_vm.tracking.podDownloaded == 1 ||
                      _vm.tracking.podDownloaded == 2) &&
                    _vm.tracking.awbNo
                      ? _c(
                          "b-button",
                          {
                            staticClass: "float-lg-right",
                            attrs: { variant: "tertiary" },
                            on: {
                              click: function($event) {
                                return _vm.downloadPod(
                                  _vm.tracking.podDownloaded,
                                  _vm.tracking.carrierID,
                                  _vm.tracking.awbNo
                                )
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-download mr-11" }),
                            _vm._v("Download POD")
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "ul",
                      { staticClass: "shipping-timeline" },
                      _vm._l(_vm.shipEvents, function(event) {
                        return _c("li", { key: event.id }, [
                          _c("span", { staticClass: "status" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.staticName(_vm.events, event.state)
                                ) +
                                " "
                            )
                          ]),
                          _c("span", { staticClass: "meta" }, [
                            _vm._v(" " + _vm._s(event.currentLocation) + " ")
                          ]),
                          _c("span", { staticClass: "meta" }, [
                            _vm._v(" " + _vm._s(event.statusTime) + " ")
                          ]),
                          _c("span", { staticClass: "meta" }, [
                            _vm._v(" " + _vm._s(event.remarks) + " ")
                          ])
                        ])
                      }),
                      0
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.shipment.hasOwnProperty("shipmentError") &&
            _vm.isShipmentError == true &&
            !_vm.shipEvents.length
              ? _c("div", { staticClass: "shipping-error-status" }, [
                  _c("div", [
                    _c("h6", [_vm._v("Error Reason:")]),
                    _c("span", { staticClass: "text-red" }, [
                      _vm._v(" " + _vm._s(_vm.shipment.shipmentError) + " ")
                    ])
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "shipping-status",
                class: _vm.shipment.finalStatus.toLowerCase()
              },
              [
                _c("span", { staticClass: "fs16 lh24 fw500" }, [
                  _vm._v("Shipping Status")
                ]),
                _c("div", { staticClass: "status-dot" }, [
                  _c("div", { staticClass: "dot" }),
                  _c("span", { staticClass: "fs16 lh24 fw500 ml-30" }, [
                    _vm._v(" " + _vm._s(_vm.shipment.finalStatus))
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "package" },
              [
                _c("span", { staticClass: "fs16 lh24 d-block mb-12 fw500" }, [
                  _vm._v("Package Details")
                ]),
                _c("b-table", {
                  staticClass: "dark-header w-footer",
                  attrs: {
                    fields: _vm.computedFileds,
                    items: _vm.items,
                    "foot-clone": "",
                    responsive: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "head(quantity)",
                      fn: function() {
                        return [_vm._v(" Qty. ")]
                      },
                      proxy: true
                    },
                    {
                      key: "head(value)",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "d-block text-right" }, [
                            _vm._v("Value")
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(dimensions(CM))",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "w120px" }, [
                            _vm._v(
                              " " +
                                _vm._s(data.item.length) +
                                " x " +
                                _vm._s(data.item.width) +
                                " x " +
                                _vm._s(data.item.height) +
                                " "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(weight)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block" }, [
                            _vm._v(_vm._s(data.item.weight))
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(SKU)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block" }, [
                            _vm._v(_vm._s(data.item.sku))
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(value)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block text-right" }, [
                            _vm._v("₹" + _vm._s(data.item.value))
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(price)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block" }, [
                            _vm._v(
                              _vm._s(
                                isNaN(data.item.price)
                                  ? ""
                                  : data.item && data.item.price == null
                                  ? "0.00"
                                  : parseFloat(data.item.price).toFixed(2)
                              )
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "foot(category)",
                      fn: function() {
                        return [_c("span", [_vm._v("Total Value")])]
                      },
                      proxy: true
                    },
                    {
                      key: "foot(name)",
                      fn: function() {
                        return [_c("span", [_vm._v("Total Value")])]
                      },
                      proxy: true
                    },
                    {
                      key: "foot(sku)",
                      fn: function() {
                        return [_c("span")]
                      },
                      proxy: true
                    },
                    {
                      key: "foot(dimensions(CM))",
                      fn: function() {
                        return [_c("span")]
                      },
                      proxy: true
                    },
                    {
                      key: "foot(weight)",
                      fn: function() {
                        return [_c("span")]
                      },
                      proxy: true
                    },
                    {
                      key: "foot(quantity)",
                      fn: function() {
                        return [_c("span")]
                      },
                      proxy: true
                    },
                    {
                      key: "foot(price)",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "d-block fw700" }, [
                            _vm._v(
                              _vm._s(
                                _vm.channels[_vm.order.channel] &&
                                  _vm.channels[_vm.order.channel].name ==
                                    "international"
                                  ? "$"
                                  : "₹"
                              ) +
                                " " +
                                _vm._s(
                                  _vm.order.totalValue
                                    ? _vm.order.totalValue
                                    : isNaN(_vm.total)
                                    ? 0
                                    : ""
                                ) +
                                " "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            ),
            _vm.order.channel == 12
              ? _c("div", { staticClass: "hello" }, [
                  _c("span", { staticClass: "fs16 lh24 fw500" }, [
                    _vm._v("Additional Details")
                  ]),
                  _c("div", { staticClass: "row pt-16" }, [
                    _c("div", { staticClass: "col-8 col-md-3" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                        },
                        [_vm._v(" IOSS Number ")]
                      ),
                      _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                        _vm._v(" " + _vm._s(_vm.order.iossNumber) + " ")
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ])
      ]),
      _c(
        "b-modal",
        {
          ref: "cancelShipmentModal",
          attrs: {
            centered: "",
            title: "Cancel Order",
            id: "cancelOrderModal",
            "modal-class": "cancel-modal"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("span", { staticClass: "fs18 lh28 fw500" }, [
                    _vm._v(" Cancel Shipmnet ")
                  ]),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "rounded" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-close-variant fw400" })]
                  )
                ]
              }
            },
            {
              key: "modal-footer",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "m-0 mr-8",
                      attrs: {
                        variant: "secondary-modal w-sm-100 mb-12 mb-md-0"
                      },
                      on: {
                        click: function($event) {
                          return _vm.cancelShipment(_vm.shipment.id)
                        }
                      }
                    },
                    [_vm._v(" Yes, cancel shipment ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-0",
                      attrs: { id: "btnHide", variant: "primary w-sm-100" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_vm._v(" No, lead me back ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.isOpen,
            callback: function($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen"
          }
        },
        [
          _c("p", { staticClass: "py-10 text-gray-800" }, [
            _vm._v(" Are you sure you want to cancel this shipment? ")
          ])
        ]
      ),
      _c("ShipmentsCancelModal", { ref: "cancelOrderModal" }),
      _c("ShipmentRebook", {
        ref: "shipmentsRebookModal",
        attrs: {
          rebookData: _vm.rebookData,
          rebookWarehouses: _vm.rebookWarehouses
        }
      }),
      _c("ShipmentBookReverse", {
        ref: "bookReverseModal",
        attrs: {
          bookReverseAddress: _vm.bookReverseAddress,
          bookReverseShipmentData: _vm.bookReverseShipmentData
        }
      }),
      _c("editShipment", {
        ref: "editShipment",
        attrs: { editformData: _vm.editformData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }